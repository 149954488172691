import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Select, Form, Input,
} from 'antd';

import {
  cleanerReviewStatuses, mediumFinals, currencies, measurements, BulkEditTypes,
} from 'cleaning-lots/constants/lot-fields';

class BulkEditLotsModal extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    bulkUpdateLots: PropTypes.func.isRequired,
    cleaners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
    ).isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  onOk = () => {
    const {
      form: { validateFieldsAndScroll, resetFields },
      closeModal,
      bulkUpdateLots,
    } = this.props;
    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        closeModal();
        bulkUpdateLots(fields);
        resetFields();
      }
    });
  }

  renderCurrencyField = () => (
    <Form.Item label="Currency">
      {this.props.form.getFieldDecorator('currency', {
        initialValue: null,
      })(
        <Select>
          <Select.Option value={null}>None</Select.Option>
          {currencies.map(currency => (
            <Select.Option key={currency} value={currency}>
              {currency}
            </Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
  );

  renderMeasurementsField = () => (
    <Form.Item label="Artwork Measurements Unit">
      {this.props.form.getFieldDecorator('artwork_measurements_unit', {
        initialValue: null,
      })(
        <Select>
          <Select.Option value={null}>None</Select.Option>
          {measurements.map(measurement => (
            <Select.Option key={measurement} value={measurement}>
              {measurement}
            </Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
  );

  renderCleanerField = () => (
    <Form.Item label="Cleaner">
      {this.props.form.getFieldDecorator('cleaner_id', {
        initialValue: null,
      })(
        <Select>
          <Select.Option value={null}>None</Select.Option>
          {this.props.cleaners.map(cleaner => (
            <Select.Option key={cleaner.id} value={cleaner.id}>
              {cleaner.name}
              {' '}
              {cleaner.surname}
            </Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
  );

  renderCleanerReviewStatusField = () => (
    <Form.Item label="Cleaner Review Status">
      {this.props.form.getFieldDecorator('cleaner_review_status', {
        initialValue: null,
      })(
        <Select>
          <Select.Option value={null}>None</Select.Option>
          {cleanerReviewStatuses.map(reviewStatus => (
            <Select.Option key={reviewStatus} value={reviewStatus}>
              {reviewStatus}
            </Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
  );

  renderPriceKindField = () => (
    <Form.Item label="Price Kind">
      {this.props.form.getFieldDecorator('price_kind', {
        initialValue: null,
      })(<Input />)}
    </Form.Item>
  );

  renderMediumFinalField = () => (
    <Form.Item label="Medium Final">
      {this.props.form.getFieldDecorator('medium_final', {
        initialValue: null,
        rules: [{
          required: true, message: 'This field is required',
        }],
      })(
        <Select>
          <Select.Option value={null}>None</Select.Option>
          {mediumFinals.map(([value, name]) => (
            <Select.Option key={value} value={value}>
              {name}
            </Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
  )

  getFieldByType = () => {
    const { type } = this.props;
    let Component;

    switch (type) {
      case BulkEditTypes.CURRENCY:
        Component = this.renderCurrencyField();
        break;
      case BulkEditTypes.MEASUREMENTS_UNIT:
        Component = this.renderMeasurementsField();
        break;
      case BulkEditTypes.CLEANER:
        Component = this.renderCleanerField();
        break;
      case BulkEditTypes.CLEANER_REVIEW_STATUS:
        Component = this.renderCleanerReviewStatusField();
        break;
      case BulkEditTypes.PRICE_KIND:
        Component = this.renderPriceKindField();
        break;
      case BulkEditTypes.MEDIUM_FINAL:
        Component = this.renderMediumFinalField();
        break;
      default:
        break;
    }

    return Component;
  }

  render() {
    const {
      visible,
      closeModal,
      type,
    } = this.props;

    const title = `Editing of ${type} for selected lots}`;

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={closeModal}
        onOk={this.onOk}
        okButtonProps={{
          onClick: this.onOk,
        }}
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
      >
        <Form onSubmit={this.onSubmit}>
          {this.getFieldByType()}
        </Form>
      </Modal>
    );
  }
}

export { BulkEditLotsModal };
