module.exports = {
  exact: 'Exact',
  circa: 'Circa',
  unknown: 'Unknown',
  established: 'Established',
  century: 'Century',
  active: 'Active',
  active_circa: 'Active Circa',
  active_century: 'Active Century',
};
