import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import {
  updateArtist,
  navigateToPrevArtist,
  navigateToNextArtist,
  getUlanData,
} from 'artist/actions/artist-page';

import EditArtistModal from 'artist/components/edit-modal/index';

import { removeEmptyFields } from 'shared/tools/form';
import { ARTIST_STATUS } from 'shared/constants/app';

const ARTIST_STATUSES = Object.values(ARTIST_STATUS);

const stateToProps = state => {
  const {
    artists: { isLoading, artistToEdit, ids, currentPage, pagesCount },
  } = state;

  const currentArtistIndex = ids.indexOf(artistToEdit.id);

  return {
    isLoading,
    artistToEdit,
    isPrevButtonDisabled: currentArtistIndex === 0 && currentPage === 1,
    isNextButtonDisabled: currentArtistIndex === ids.length - 1 && currentPage === pagesCount,
    artistQualifiers: state.artistQualifier.items.map(x => x.name),
    statuses: ARTIST_STATUSES,
    isAdmin: state.user.userInfo.role.name === 'admin',
  };
};

const dispatchToProps = dispatch => ({
  updateArtist: (id, fields) => {
    dispatch(updateArtist(id, removeEmptyFields(fields)));
  },
  navigateToPrevArtist: () => dispatch(navigateToPrevArtist()),
  navigateToNextArtist: () => dispatch(navigateToNextArtist()),
  getUlanData: (ulanId, artistData) => dispatch(getUlanData(ulanId, artistData)),
});

export default compose(connect(stateToProps, dispatchToProps), Form.create())(EditArtistModal);
