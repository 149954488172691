import { handleActions } from 'redux-actions';
import produce from 'immer';

import {
  getCleanersRequest,
  getCleanersSuccess,
  getCleanersError,
} from 'cleaning-lots/actions/cleaners';

import { loadingProducer, errorProducer, receiveDataProducer } from 'shared/helpers/producers';

const initState = {
  isLoading: false,
  success: false,
  count: 0,
  ids: [],
  items: {},
};

export const cleanersReducer = handleActions(
  {
    [getCleanersRequest]: produce(loadingProducer),
    [getCleanersSuccess]: produce(receiveDataProducer),
    [getCleanersError]: produce(errorProducer),
  },
  initState,
);
