import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Form } from 'antd';

class EditPriorityModal extends React.PureComponent {
  static propTypes = {
    editPriority: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,

    isVisible: PropTypes.bool.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    priorities: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        key: PropTypes.string,
      }),
    ).isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  editPriority = () => {
    const { selectedIds, form } = this.props;
    const priority = form.getFieldValue('priority') || null;

    this.props.editPriority(selectedIds, priority);
  };

  renderFormFields = () => (
    <Select className="edit-priority-modal__options">
      <Select.Option value={null} className="edit-priority-modal__option">
        None
      </Select.Option>
      {this.props.priorities.map(item => (
        <Select.Option key={item.key} value={item.value} className="edit-priority-modal__option">
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Modal
        title="Edit Priority"
        visible={this.props.isVisible}
        onCancel={this.props.closeModal}
        onOk={this.editPriority}
        className="edit-priority-modal"
      >
        <Form>
          <Form.Item label="Auction Priority">
            {getFieldDecorator('priority', {
              initialValue: null,
            })(this.renderFormFields())}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default EditPriorityModal;
