export const TABS = [
  {
    name: 'Cleaning UI',
    path: '/cleaning-lots/cleaning-ui',
  },
  {
    name: 'Admin Cleaning UI',
    path: '/cleaning-lots/admin-cleaning-ui',
  },
];
