import { Button, Typography } from 'antd';

import CustomRow from 'shared/components/CustomRow';
import PropTypes from 'prop-types';
import React from 'react';

export default class AdminCleaningActions extends React.PureComponent {
  static propTypes = {
    openDeleteModal: PropTypes.func.isRequired,
    openAssignCleanerModal: PropTypes.func.isRequired,
    // finishCleaning: PropTypes.func.isRequired,
    openPublishModal: PropTypes.func.isRequired,
    openUnpublishModal: PropTypes.func.isRequired,
    openNotesModal: PropTypes.func.isRequired,
    // unpublishLots: PropTypes.func.isRequired,
    ignoreLots: PropTypes.func.isRequired,
    openEditPriorityModal: PropTypes.func.isRequired,

    selectedItemsCount: PropTypes.number.isRequired,

    isButtonsDisabled: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const { isButtonsDisabled, selectedItemsCount, isAdmin, isSuperAdmin } = this.props;

    return (
      <CustomRow>
        <Button.Group>
          {isAdmin && (
            <React.Fragment>
              {isSuperAdmin && (
                <Button disabled={isButtonsDisabled} onClick={this.props.openDeleteModal}>
                  Delete Auction
                </Button>
              )}
              <Button disabled={isButtonsDisabled} onClick={this.props.openEditPriorityModal}>
                Edit Priority
              </Button>
              <Button disabled={isButtonsDisabled} onClick={this.props.openAssignCleanerModal}>
                Assign To Clean
              </Button>
              <Button disabled={isButtonsDisabled} onClick={this.props.openNotesModal} style={{marginRight:'10px'}}>
                Add notes
              </Button>
            </React.Fragment>
          )}
          {/* <Button disabled={isButtonsDisabled} onClick={this.props.finishCleaning}>
            Finish Cleaning
          </Button> */}
          {isAdmin && (
            <React.Fragment>
              <Button disabled={isButtonsDisabled} onClick={this.props.openPublishModal}>
                Publish
              </Button>
              <Button disabled={isButtonsDisabled} onClick={this.props.openUnpublishModal}>
                Unpublish
              </Button>
              <Button disabled={isButtonsDisabled} onClick={this.props.ignoreLots}>
                Ignore
              </Button>
            </React.Fragment>
          )}
        </Button.Group>
        <Typography.Text
          style={{
            marginLeft: '20px',
          }}
        >
          Selected:
          {` ${selectedItemsCount}`}
        </Typography.Text>
      </CustomRow>
    );
  }
}
