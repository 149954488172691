import axios from 'axios';

import { STORAGE_KEY } from 'shared/constants/app';

import { logOut } from 'main/actions/user';
import store from 'main/store';

import SessionStorageTool from './session-storage';

axios.interceptors.request.use(config => {
  const storedUser = JSON.parse(SessionStorageTool.get(STORAGE_KEY));
  const token = storedUser?.api_token;

  if (!token) {
    return config;
  }

  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: `Bearer ${token}`,
    },
  };
}, Promise.reject);

axios.interceptors.response.use(
  r => r,
  error => {
    if (error.response.status === 401) {
      store.dispatch(logOut());
    }
  },
);

const createRequest = (method, url, options) =>
  axios({
    method,
    url,
    ...options,
  });

export const Request = class {
  static get(url, options = {}) {
    return createRequest('get', url, options);
  }

  static post(url, options = {}) {
    return createRequest('post', url, options);
  }

  static delete(url, options = {}) {
    return createRequest('delete', url, options);
  }

  static patch(url, options = {}) {
    return createRequest('patch', url, options);
  }

  static put(url, options = {}) {
    return createRequest('put', url, options);
  }
};
