import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { publish, closePublishModal } from 'cleaning-lots/actions/publish';
import PublishModal from 'cleaning-lots/components/AdminCleaningUI/PublishModal';

const stateToProps = state => ({
  isVisible: state.publish.isPublishModalOpened,
});

const dispatchToProps = {
  publish,
  closeModal: closePublishModal,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(PublishModal);
