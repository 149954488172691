import {
  getLots,
  loadInitialData,
  publishExcludingIgnored,
  publishLots,
  setBulkEditType,
  setLotToEdit,
  setPage,
  setSelectAll,
  setSelectedLots,
  setSortBy,
  setSortDirection,
  setToInitialState,
  setUnselectedLots,
  unpublishLots,
  setTableFilters,
} from 'cleaning-lots/actions/cleaning';

import CleaningUI from 'cleaning-lots/components/CleaningUI';
import { connect } from 'react-redux';
import { denormalizeAndMapItems } from 'cleaning-lots/normalization/denormalize';
import {
  finishCleaning,
} from 'cleaning-lots/actions/admin-cleaning';

const calculateTableWidth = columns =>
  columns.reduce((totalWidth, column) => totalWidth + column.width, 0);

const stateToProps = state => {
  const isAdmin = state.user.userInfo.role.name === 'admin';
  const { ids, selectedIds, unselectedIds, isAllSelected, activeAuctionId, reviewRequiredReasons } = state.lots;

  const columns = isAdmin
    ? state.lots.columns
    : state.lots.columns.filter(column => column.key !== 'cleaner');

  const idsToDisplayAsSelected = isAllSelected
    ? ids.filter(id => !unselectedIds.includes(id))
    : selectedIds;

  return {
    lotsRows: denormalizeAndMapItems(state.lots, state.cleaners),
    ids,
    count: state.lots.count,
    isLoading: state.lots.isLoading,
    selectedIds: idsToDisplayAsSelected,
    unselectedIds,
    isBulkEditAllowed: !!state.lots.selectedIds.length || isAllSelected,
    currentPage: state.lots.currentPage,
    columns,
    tableWidth: calculateTableWidth(columns),
    sortDir: state.lots.sortDir,
    sortBy: state.lots.sortBy,
    lotToEdit: state.lots.lotToEdit,
    bulkEditType: state.lots.bulkEditType,
    isAllSelected: state.lots.isAllSelected,
    isAdmin,
    activeAuctionId,
    tableFilters: state.lots.tableFilters,
  };
};

const dispatchToProps = {
  getLots,
  setSelectedLots,
  setUnselectedLots,
  setPage,
  setSortDirection,
  setSortBy,
  setToInitialState,
  setLotToEdit,
  setBulkEditType,
  setSelectAll,
  publishLots,
  unpublishLots,
  publishExcludingIgnored,
  loadInitialData,
  finishCleaning,
  setTableFilters,
};

export default connect(stateToProps, dispatchToProps)(CleaningUI);
