import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Form, Input, DatePicker } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { auctionModel, optionModel } from 'cleaning-lots/constants/auction-model';

const DATE_FORMAT = 'YYYY-MM-DD';

export default class EditAuctionModal extends PureComponent {
  static propTypes = {
    auctionToEdit: auctionModel.isRequired,
    isVisible: PropTypes.bool.isRequired,

    closeModal: PropTypes.func.isRequired,
    updateAuction: PropTypes.func.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,

    cleaners: optionModel.isRequired,
    priorities: optionModel.isRequired,
    auctionStatuses: optionModel.isRequired,

    isAdmin: PropTypes.bool.isRequired,
  };

  onOk = () => {
    const { form, auctionToEdit, closeModal, updateAuction } = this.props;

    form.validateFieldsAndScroll((err, fields) => {
      if (!err) {
        closeModal();
        updateAuction(auctionToEdit.id, fields);
      }
    });
  };

  renderDropdown = (items, isShowNone = true) => (
    <Select>
      {isShowNone && <Select.Option value={null}>None</Select.Option>}
      {items.map(item => (
        <Select.Option key={item.key} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );

  renderDropdownAuctionStatus = items => (
    <Select disabled={this.props.isFieldsDisabled}>
      {items.map(item => (
        <Select.Option key={item.key} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      cleaners,
      auctionToEdit,
      isVisible,
      closeModal,
      priorities,
      auctionStatuses,
      isAdmin,
    } = this.props;

    const auctionDate = auctionToEdit.date ? moment(auctionToEdit.date, DATE_FORMAT) : null;

    const auctionDateEnd = auctionToEdit.date_end
      ? moment(auctionToEdit.date_end, DATE_FORMAT)
      : auctionDate;

    return (
      <Modal
        title="Edit auction"
        visible={isVisible}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !isFieldsTouched(),
          onClick: this.onOk,
        }}
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
      >
        <Form onSubmit={this.onSubmit}>
          {isAdmin && (
            <React.Fragment>
              <Form.Item label="Cleaner">
                {getFieldDecorator('cleaner_id', {
                  initialValue: auctionToEdit.cleaner_id,
                })(this.renderDropdown(cleaners))}
              </Form.Item>
              <Form.Item label="Auction Name">
                {getFieldDecorator('name', {
                  initialValue: auctionToEdit.name || null,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Auction House Name">
                {getFieldDecorator('auction_house_name', {
                  initialValue: auctionToEdit.auction_house_name || null,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Auction Location">
                {getFieldDecorator('location', {
                  initialValue: auctionToEdit.location || null,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Auction Number">
                {getFieldDecorator('number', {
                  initialValue: auctionToEdit.number || null,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Auction Start Date">
                {getFieldDecorator('date', {
                  initialValue: auctionDate,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<DatePicker format={DATE_FORMAT} />)}
              </Form.Item>
              <Form.Item label="Auction End Date">
                {getFieldDecorator('date_end', {
                  initialValue: auctionDateEnd,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<DatePicker format={DATE_FORMAT} />)}
              </Form.Item>
              <Form.Item label="Auction Status">
                {getFieldDecorator('auction_status', {
                  initialValue: auctionToEdit.auction_status,
                })(this.renderDropdownAuctionStatus(auctionStatuses))}
              </Form.Item>
              <Form.Item label="Priority">
                {getFieldDecorator('priority', {
                  initialValue: auctionToEdit.priority,
                })(this.renderDropdown(priorities))}
              </Form.Item>
            </React.Fragment>
          )}
          <Form.Item label="# of lots(manual)">
            {getFieldDecorator('nb_of_lots_manual', {
              initialValue: auctionToEdit.nb_of_lots_manual,
              rules: [
                {
                  validator: (rule, value, cb) =>
                    !isEmpty(value) && value <= 0 ? cb('Must be greater than 0') : cb(),
                },
              ],
            })(<Input type="number" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
