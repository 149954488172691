import { loadInitialData, setToInitialState } from 'dashboard/actions/dashboard';

import Dashboard from 'dashboard/components/Dashboard';
import { connect } from 'react-redux';

const stateToProps = state => {
  const { dashboardData } = state.dashboard;

  return {
    dashboardData,
  };
};

const dispatchToProps = {
  loadInitialData,
  setToInitialState,
};

export default connect(stateToProps, dispatchToProps)(Dashboard);
