import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import './index.scss';

export default class Tabs extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,

    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { items } = this.props;

    const className = classNames('tabs', this.props.className);

    return (
      <div className={className}>
        {items.map(item => (
          <NavLink
            key={item.name}
            to={item.path}
            className="tabs__tab"
            activeClassName="tabs__tab_selected"
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    );
  }
}
