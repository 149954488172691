import { handleActions } from 'redux-actions';
import produce from 'immer';

import { openPublishModal, closePublishModal } from 'cleaning-lots/actions/publish';

const initState = {
  isPublishModalOpened: false,
};

const publishReducer = handleActions(
  {
    [openPublishModal]: produce(draft => {
      draft.isPublishModalOpened = true;
    }),
    [closePublishModal]: produce(draft => {
      draft.isPublishModalOpened = false;
    }),
  },
  initState,
);

export { publishReducer };
