import {
  getSystemStatusData,
  getSystemStatusDataError,
  getSystemStatusDataSuccess,
  setToInitialState,
} from 'system-status/actions/system-status';

import { errorProducer } from 'shared/helpers/producers';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const initState = {
  systemStatusData: [],
};

const setSystemStatusDataProducer = (draft, action) => {
  draft.systemStatusData = {
    crawlAuctionJobs: action.payload.crawlAuctionJobs.map(item => ({
      ...item,
      key: item.auctionHouseId,
    })),
    crawlLotJobs: action.payload.crawlLotJobs.map(item => ({ ...item, key: item.auctionHouseId })),
    parseLotJobs: action.payload.parseLotJobs.map(item => ({ ...item, key: item.auctionHouseId })),

    crawlUpcomingAuctionsJobs: action.payload.crawlUpcomingAuctionsJobs.map(item => ({
      ...item,
      key: item.auctionHouseId,
    })),
    crawlPastAuctionsJobs: action.payload.crawlPastAuctionsJobs.map(item => ({
      ...item,
      key: item.auctionHouseId,
    })),
  };
  draft.isLoading = false;
};

export const systemStatusReducer = handleActions(
  {
    [setToInitialState]: produce(draft => {
      draft.systemStatusData = [];
    }),
    [getSystemStatusData]: produce(draft => {
      draft.isLoading = true;
    }),
    [getSystemStatusDataSuccess]: produce(setSystemStatusDataProducer),
    [getSystemStatusDataError]: produce(errorProducer),
  },
  initState,
);
