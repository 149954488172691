import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Radio, Checkbox, Input } from 'antd';

const EditNotesModal = ({ selectedIds, setNotes, isVisible, closeModal, form }) => {
  const { getFieldDecorator, getFieldValue } = form;

  const onOk = () => {
    form.validateFieldsAndScroll((err, fields) => {
      if (!err) {
        closeModal();
        fields.use_apr = fields.use_apr === -1 ? null : fields.use_apr;
        fields.custom_note = fields.add_custom_note && fields.custom_note ? fields.custom_note : '';
        setNotes(selectedIds, fields);
      }
    });
  };

  return (
    <Modal title="Add Notes" visible={isVisible} onOk={onOk} onCancel={closeModal}>
      <Form>
        <Form.Item>
          {getFieldDecorator('clean_first', {
            defaultChecked: false,
            valuePropName: 'checked',
          })(<Checkbox>Clean First</Checkbox>)}
        </Form.Item>
        <Form.Item rules={[{ required: true }]}>
          {getFieldDecorator('use_apr', {
            initialValue: -1,
          })(
            <Radio.Group>
              <Radio value={-1}>Neither</Radio>
              <br />
              <Radio value={true}>Use APR</Radio>
              <br />
              <Radio value={false}>Do Not Use APR</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('add_custom_note', {
            defaultChecked: false,
            valuePropName: 'checked',
          })(<Checkbox>Add Custom Note</Checkbox>)}
        </Form.Item>
        {getFieldValue('add_custom_note') ? (
          <Form.Item>{getFieldDecorator('custom_note')(<Input placeholder="Note" />)}</Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};

EditNotesModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
  }).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,

  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,

  setNotes: PropTypes.func.isRequired,
};

export default EditNotesModal;
