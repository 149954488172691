import PropTypes from 'prop-types';

export const lotModel = PropTypes.shape({
  artist_data: PropTypes.arrayOf(
    PropTypes.shape({
      birth: PropTypes.number,
      death: PropTypes.number,
      name: PropTypes.string.isRequired,
      nationality: PropTypes.string,
    }),
  ),
  artist_qualifier: PropTypes.string,
  artwork_condition_in: PropTypes.string,
  artwork_creation_year: PropTypes.number,
  artwork_description: PropTypes.string,
  artwork_edition_current: PropTypes.number,
  artwork_edition_size: PropTypes.number,
  artwork_exhibited: PropTypes.string,
  artwork_literature: PropTypes.string,
  artwork_markings: PropTypes.string,
  artwork_materials: PropTypes.string,
  artwork_measurements_depth: PropTypes.number,
  artwork_measurements_height: PropTypes.number,
  artwork_measurements_unit: PropTypes.string,
  artwork_measurements_width: PropTypes.number,
  artwork_name: PropTypes.string.isRequired,
  artwork_provenance: PropTypes.string,
  artwork_size_notes: PropTypes.string,
  auction_house_id: PropTypes.number.isRequired,
  auction_house_name: PropTypes.string.isRequired,
  auction_location: PropTypes.string.isRequired,
  auction_name: PropTypes.string.isRequired,
  auction_num: PropTypes.string,
  auction_start_date: PropTypes.string.isRequired,
  classified_artist_id: PropTypes.number,
  cleaner_id: PropTypes.string,
  currency: PropTypes.string,
  lot_image_url: PropTypes.string,
  lot_origin_url: PropTypes.string.isRequired,
  lot_num: PropTypes.string,
  medium_final: PropTypes.string.isRequired,
  price_estimate_max: PropTypes.number,
  price_estimate_min: PropTypes.number,
  price_kind: PropTypes.string,
  price_sold: PropTypes.number,
  publish_status: PropTypes.string,
  publish_status_text: PropTypes.string,
  raw_lot_id: PropTypes.number.isRequired,
  review_status: PropTypes.string,
});

export const reviewStatuses = ['in review', 'ignored', 'reviewed'];

export const cleanerReviewStatuses = [...reviewStatuses, 'needs further review'];

export const cleanerReviewStatusesForPendingArtist = ['needs further review'];

export const publishStatuses = ['error', 'published'];

export const currencies = [
  'EUR',
  'GBP',
  'NZD',
  'HKD',
  'USD',
  'INR',
  'CHF',
  'CNY',
  'AUD',
  'RMB',
  'CZK',
  'SEK',
  'TWD',
  'CAD',
  'MXN',
  'ZAR',
  'PHP',
  'PLN',
  'NOK',
  'DKK',
  'NGN',
  'IDR',
  'KRW',
  'HUF',
  'BRL',
  'ARS',
  'SGD',
  'JPY',
  'MAD',
  'MYR',
  'BEF',
  'ATS',
  'FRF',
  'ITL',
  'NLG',
  'DEM',
  'IEP',
  'ESP',
  'FIM',
  'GRD',
  'VEF',
  'SAR',
  'SKK',
  'VEB',
  'THB',
  'RUR',
  'AED',
];

export const measurements = ['centimeters', 'inches', 'unknown'];

export const mediumFinals = [
  ['paintings', 'paintings'],
  ['works on paper', 'works on paper'],
  ['works of glass', 'works of glass'],
  ['prints', 'prints'],
  ['sculpture', 'Sculpture & Installations'],
  ['photographs', 'photographs'],
  ['jewelry', 'jewelry'],
  ['undetermined', 'undetermined'],
  ['ceramics', 'ceramics'],
  ['furniture', 'furniture'],
  ['other', 'other'],
  ['NFT', 'NFT'],
  ['Books & Manuscripts', 'Books & Manuscripts'],
  ['Video & Digital', 'Video & Digital'],
  ['Design', 'Design'],
];

export const mediumFinalsMapping = mediumFinals.reduce(
  (acc, [value, name]) => ({ [[value]]: name, ...acc }),
  {},
);

export const BulkEditTypes = {
  CURRENCY: 'currency',
  MEASUREMENTS_UNIT: 'measurements unit',
  CLEANER: 'cleaner',
  CLEANER_REVIEW_STATUS: 'cleaner review status',
  PRICE_KIND: 'price kind',
  MEDIUM_FINAL: 'medium final',
};
