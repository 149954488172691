import { Table, Typography } from 'antd';

import Layout from 'main/containers/Layout';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const { Title } = Typography;
export default class Dashboard extends React.PureComponent {
  static propTypes = {
    loadInitialData: PropTypes.func.isRequired,
    setToInitialState: PropTypes.func.isRequired,
    dashboardData: PropTypes.arrayOf(
      PropTypes.shape({
        auctionHouseName: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  componentDidMount() {
    this.props.loadInitialData();
  }

  componentWillUnmount() {
    this.props.setToInitialState();
  }

  render() {
    const { dashboardData } = this.props;
    const date24HHoursAgo = moment()
      .subtract('1', 'day')
      .format('YYYY-MM-DD');
    const date90DaysAgo = moment()
      .subtract('90', 'day')
      .format('YYYY-MM-DD');
    const date2DaysAgo = moment()
      .subtract('2', 'day')
      .format('YYYY-MM-DD');
    return (
      <Layout>
        <Title level={3}>Auction House Stats</Title>
        <Table
          rowKey="raw_lot_id"
          // loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={dashboardData}
          columns={[
            {
              title: 'Auction House Name',
              key: 'auctionHouseName',
              dataIndex: 'auctionHouseName',
              sorter: false,
              render: value => value || '',
            },
            {
              title: 'Auction House Scheduled Run (UTC)',
              key: 'auctionHouseSchedule',
              dataIndex: 'auctionHouseSchedule',
              sorter: false,
              render: value => value || '',
            },
            {
              title: 'Auctions Published Past 24 hrs',
              key: 'auctionsPublishedPast24Hours',
              dataIndex: 'auctionsPublishedPast24Hours',
              sorter: false,
              render: (value, record) => (
                <a
                  href={`/cleaning-lots/admin-cleaning-ui?auctionHouseId=${record.auctionHouseId}&publishedDateFrom=${date24HHoursAgo}&auctionStatus=`}
                >
                  {value || 0}
                </a>
              ),
            },
            {
              title: 'Auctions Crawled Past 24 hrs',
              key: 'auctionsCrawledPast24Hours',
              dataIndex: 'auctionsCrawledPast24Hours',
              sorter: false,
              render: (value, record) => (
                <a
                  href={`/cleaning-lots/admin-cleaning-ui?auctionHouseId=${record.auctionHouseId}&createdDateFrom=${date24HHoursAgo}&auctionStatus=`}
                >
                  {value || 0}
                </a>
              ),
            },
            {
              title: 'Completed Auctions w/o Sale Prices (>=80%)',
              key: 'auctionMissingPrices',
              dataIndex: 'auctionMissingPrices',
              sorter: false,
              render: (value, record) => (
                <a
                  href={`/cleaning-lots/admin-cleaning-ui?auctionHouseId=${record.auctionHouseId}&auctionStatus=except_ignored&auctionFlag=no_sale_prices&auctionDateFrom=${date90DaysAgo}&auctionDateTo=${date2DaysAgo}`}
                >
                  {value || 0}
                </a>
              ),
            },
            {
              title: 'Auctions Missing Artists (>=80%)',
              key: 'auctionMissingArtists',
              dataIndex: 'auctionMissingArtists',
              sorter: false,
              render: (value, record) => (
                <a
                  href={`/cleaning-lots/admin-cleaning-ui?auctionHouseId=${record.auctionHouseId}&auctionStatus=except_ignored&auctionFlag=missing_artists&createdDateFrom=${date90DaysAgo}`}
                >
                  {value || 0}
                </a>
              ),
            },
            {
              title: 'Auctions with APR recs (>=75%)',
              key: 'auctionAprMoreThan75',
              dataIndex: 'auctionAprMoreThan75',
              sorter: false,
              render: (value, record) => (
                <a
                  href={`/cleaning-lots/admin-cleaning-ui?auctionHouseId=${record.auctionHouseId}&auctionStatus=except_ignored&auctionFlag=high_apr&createdDateFrom=${date90DaysAgo}`}
                >
                  {value || 0}
                </a>
              ),
            },
          ]}
          size="small"
          pagination={false}
        />
      </Layout>
    );
  }
}
