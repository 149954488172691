import './index.scss';

import {
  AuctionFlags,
  AuctionFlagsLabels,
  AuctionNotesTypes,
  AuctionStatus,
} from 'cleaning-lots/constants/raw-auction';
import { Button, DatePicker, Form, Input, Select } from 'antd';

import { FIELDS } from 'cleaning-lots/constants/search-fields';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export class Search extends React.PureComponent {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isFieldsDisabled: PropTypes.bool.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
      resetFields: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      isFieldsTouched: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    cleaners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
    ).isRequired,
    auctionStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    auctionFlags: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    auctionHouses: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    priorities: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    findAuctions: PropTypes.func.isRequired,

    loadInitialData: PropTypes.func.isRequired,

    setFilterLink: PropTypes.func.isRequired,

    auctionHouseId: PropTypes.string,
    setAuctionHouse: PropTypes.func.isRequired,

    createdDateFrom: PropTypes.string,
    setCreatedDateFrom: PropTypes.func.isRequired,

    createdDateTo: PropTypes.string,
    setCreatedDateTo: PropTypes.func.isRequired,

    publishedDateFrom: PropTypes.string,
    setPublishedDateFrom: PropTypes.func.isRequired,

    publishedDateTo: PropTypes.string,
    setPublishedDateTo: PropTypes.func.isRequired,

    auctionStatus: PropTypes.string,
    setAuctionStatus: PropTypes.func.isRequired,

    auctionFlag: PropTypes.string,
    setAuctionFlag: PropTypes.func.isRequired,

    auctionDateFrom: PropTypes.string,
    setAuctionDateFrom: PropTypes.func.isRequired,

    auctionDateTo: PropTypes.string,
    setAuctionDateTo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    auctionHouseId: '',
    createdDateFrom: '',
    createdDateTo: '',
    publishedDateFrom: '',
    publishedDateTo: '',
    auctionStatus: '',
    auctionFlag: '',
    auctionDateFrom: '',
    auctionDateTo: '',
  };

  componentWillMount() {
    this.props.loadInitialData();
  }

  onSearch = () => {
    const {
      auctionHouseId,
      createdDateFrom,
      createdDateTo,
      publishedDateFrom,
      publishedDateTo,
      auctionStatus,
      auctionFlag,
      auctionDateFrom,
      auctionDateTo,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);

    const auctionHouseIdQueryParams = params.get('auctionHouseId');
    const createdDateFromQueryParams = params.get('createdDateFrom');
    const createdDateToQueryParams = params.get('createdDateTo');
    const publishedDateFromQueryParams = params.get('publishedDateFrom');
    const publishedDateToQueryParams = params.get('publishedDateTo');
    const auctionStatusQueryParams = params.get('auctionStatus');
    const auctionFlagQueryParams = params.get('auctionFlag');
    const auctionDateFromQueryParams = params.get('auctionDateFrom');
    const auctionDateToQueryParams = params.get('auctionDateTo');

    const values = this.props.form.getFieldsValue();

    let startCreatedAt = createdDateFrom || createdDateFromQueryParams;
    if (startCreatedAt) {
      startCreatedAt = moment(startCreatedAt).format('YYYY-MM-DD 00:00:00');
    }

    const [clean_first, use_apr, ...rest] = AuctionNotesTypes[values.auction_note];
    delete values.auction_note;
    values.clean_first = clean_first;
    values.use_apr = use_apr;
    values.auction_house_id = auctionHouseId || auctionHouseIdQueryParams;
    values.start_created_at = createdDateFrom || createdDateFromQueryParams;
    values.end_created_at = createdDateTo || createdDateToQueryParams;
    values.start_published_at = publishedDateFrom || publishedDateFromQueryParams;
    values.end_published_at = publishedDateTo || publishedDateToQueryParams;
    values.auction_status = auctionStatus || auctionStatusQueryParams;
    values.auction_flag = auctionFlag || auctionFlagQueryParams;
    values.start_date = auctionDateFrom || auctionDateFromQueryParams;
    values.end_date = auctionDateTo || auctionDateToQueryParams;

    console.log('[onSearch]', values);

    this.props.findAuctions(values);
  };

  onAuctionHouseChange(auctionHouseId) {
    this.props.setAuctionHouse(auctionHouseId);
    this.setFilterParamLink({ auctionHouseId });
  }

  onCreatedStartDateChange(date) {
    const formattedDate = date ? date.format() : null;
    this.props.setCreatedDateFrom(formattedDate);
    this.setFilterParamLink({ createdDateFrom: formattedDate });
  }

  onCreatedEndDateChange(date) {
    const formattedDate = date ? date.format() : null;
    this.props.setCreatedDateTo(formattedDate);
    this.setFilterParamLink({ createdDateTo: formattedDate });
  }

  onPublishedStartDateChange(date) {
    const formattedDate = date ? date.format() : null;
    this.props.setPublishedDateFrom(formattedDate);
    this.setFilterParamLink({ publishedDateFrom: formattedDate });
  }

  onPublishedEndDateChange(date) {
    const formattedDate = date ? date.format() : null;
    this.props.setPublishedDateTo(formattedDate);
    this.setFilterParamLink({ publishedDateTo: formattedDate });
  }

  onAuctionStatusChange(auctionStatus) {
    this.props.setAuctionStatus(auctionStatus);
    this.setFilterParamLink({ auctionStatus });
  }

  onAuctionFlagChange(auctionFlag) {
    this.props.setAuctionFlag(auctionFlag);
    this.setFilterParamLink({ auctionFlag });
  }

  onAuctionStartDateChange(date) {
    const formattedDate = date ? date.format() : null;
    this.props.setAuctionDateFrom(formattedDate);
    this.setFilterParamLink({ auctionDateFrom: formattedDate });
  }

  onAuctionEndDateChange(date) {
    const formattedDate = date ? date.format() : null;
    this.props.setAuctionDateTo(formattedDate);
    this.setFilterParamLink({ auctionDateTo: formattedDate });
  }

  setFilterParamLink(params) {
    const {
      auctionHouseId,
      createdDateFrom,
      createdDateTo,
      publishedDateFrom,
      publishedDateTo,
      auctionStatus,
      auctionFlag,
      auctionDateFrom,
      auctionDateTo,
    } = params;

    this.props.setFilterLink({
      auctionHouseId: auctionHouseId !== undefined ? auctionHouseId : this.props.auctionHouseId,
      createdDateFrom: createdDateFrom !== undefined ? createdDateFrom : this.props.createdDateFrom,
      createdDateTo: createdDateTo !== undefined ? createdDateTo : this.props.createdDateTo,
      publishedDateFrom:
        publishedDateFrom !== undefined ? publishedDateFrom : this.props.publishedDateFrom,
      publishedDateTo: publishedDateTo !== undefined ? publishedDateTo : this.props.publishedDateTo,
      auctionStatus: auctionStatus !== undefined ? auctionStatus : this.props.auctionStatus,
      auctionFlag: auctionFlag !== undefined ? auctionFlag : this.props.auctionFlag,
      auctionDateFrom: auctionDateFrom !== undefined ? auctionDateFrom : this.props.auctionDateFrom,
      auctionDateTo: auctionDateTo !== undefined ? auctionDateTo : this.props.auctionDateTo,
    });
  }

  onClear = () => {
    this.props.form.resetFields();
    this.props.setAuctionHouse(null);
    this.props.setCreatedDateFrom(null);
    this.props.setFilterLink({
      auctionHouseId: null,
      createdDateFrom: null,
      publishedDateFrom: null,
      auctionStatus: null,
      auctionFlag: null,
      auctionDateFrom: null,
      auctionDateTo: null,
    });
    this.props.findAuctions();
  };

  renderInputs = () => {
    const { auctionHouses, form, auctionHouseId } = this.props;

    return FIELDS.map(field => {
      if (field.name === 'auction_house_name') {
        return (
          <Form.Item
            key={field.name}
            label="Auction House"
            className="admin-cleaning-form__dropdown"
          >
            <Select onSelect={value => this.onAuctionHouseChange(value)} value={auctionHouseId}>
              <Select.Option value={null}> - </Select.Option>
              {auctionHouses.map(item => (
                <Select.Option key={item.key} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }
      return (
        <Form.Item key={field.name} label={field.placeholder}>
          {form.getFieldDecorator(field.name)(
            <Input placeholder={field.placeholder} disabled={this.props.isFieldsDisabled} />,
          )}
        </Form.Item>
      );
    });
  };

  renderDropdown = (items, isShowNone = true) => (
    <Select disabled={this.props.isFieldsDisabled}>
      <Select.Option value={null}>All</Select.Option>
      <Select.Option value="except_ignored">All but Ignored</Select.Option>
      {isShowNone && <Select.Option value="none">None</Select.Option>}
      {items.map(item => (
        <Select.Option key={item.key} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );

  renderDropdownAuctionStatus = items => (
    <Select disabled={this.props.isFieldsDisabled}>
      {items.map(item => (
        <Select.Option key={item.key} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );

  render() {
    const {
      form,
      cleaners,
      isAdmin,
      isFieldsDisabled,
      auctionStatuses,
      auctionFlags,
      priorities,
      createdDateFrom,
      createdDateTo,
      publishedDateFrom,
      publishedDateTo,
      auctionStatus,
      auctionFlag,
      auctionDateFrom,
      auctionDateTo,
    } = this.props;

    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        <div className="admin-cleaning-form">
          <Form layout="inline">
            {this.renderInputs()}

            {isAdmin && (
              <Form.Item label="Cleaner" className="admin-cleaning-form__dropdown">
                {getFieldDecorator('cleaner_id', {
                  initialValue: null,
                })(this.renderDropdown(cleaners))}
              </Form.Item>
            )}

            <Form.Item label="Auction Status" className="admin-cleaning-form__dropdown">
              <Select onChange={value => this.onAuctionStatusChange(value)} value={auctionStatus}>
                {auctionStatuses.map(item => (
                  <Select.Option key={item.key} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Auction Flag" className="admin-cleaning-form__dropdown">
              <Select onChange={value => this.onAuctionFlagChange(value)} value={auctionFlag}>
                {auctionFlags.map(item => (
                  <Select.Option key={item.key} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Priority" className="admin-cleaning-form__dropdown">
              {getFieldDecorator('priority', {
                initialValue: null,
              })(this.renderDropdown(priorities))}
            </Form.Item>

            <br />
            <Form.Item label="Auction Notes" className="admin-cleaning-form__dropdown">
              {getFieldDecorator('auction_note', {
                initialValue: 0,
              })(
                <Select>
                  {AuctionNotesTypes.map(([clean_first, use_apr, label], i) => (
                    <Select.Option key={label} value={i}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <div className="date-row date-row_first">
              <Form.Item label="Created Date From:" className="admin-cleaning-form__datepicker">
                <DatePicker
                  disabledDate={currentDate => createdDateTo && currentDate > moment(createdDateTo)}
                  disabled={isFieldsDisabled}
                  onChange={value => this.onCreatedStartDateChange(value)}
                  value={createdDateFrom ? moment(createdDateFrom) : null}
                />
              </Form.Item>
              <Form.Item label="To:" className="admin-cleaning-form__datepicker">
                <DatePicker
                  disabledDate={currentDate => currentDate < moment(createdDateFrom)}
                  disabled={isFieldsDisabled}
                  onChange={value => this.onCreatedEndDateChange(value)}
                  value={createdDateTo ? moment(createdDateTo) : null}
                />
              </Form.Item>
            </div>

            <div className="date-row date-row_first">
              <Form.Item label="Published Date From:" className="admin-cleaning-form__datepicker">
                <DatePicker
                  disabledDate={currentDate =>
                    publishedDateTo && currentDate > moment(publishedDateTo)
                  }
                  disabled={isFieldsDisabled}
                  onChange={value => this.onPublishedStartDateChange(value)}
                  value={publishedDateFrom ? moment(publishedDateFrom) : null}
                />
              </Form.Item>
              <Form.Item label="To:" className="admin-cleaning-form__datepicker">
                <DatePicker
                  disabledDate={currentDate => currentDate < moment(publishedDateFrom)}
                  disabled={isFieldsDisabled}
                  onChange={value => this.onPublishedEndDateChange(value)}
                  value={publishedDateTo ? moment(publishedDateTo) : null}
                />
              </Form.Item>
            </div>

            <div className="date-row">
              <Form.Item label="Auction Date From:" className="admin-cleaning-form__datepicker">
                <DatePicker
                  disabledDate={currentDate => auctionDateTo && currentDate > moment(auctionDateTo)}
                  disabled={isFieldsDisabled}
                  onChange={value => this.onAuctionStartDateChange(value)}
                  value={auctionDateFrom ? moment(auctionDateFrom) : null}
                />
                ,
              </Form.Item>
              <Form.Item label="To:" className="admin-cleaning-form__datepicker">
                <DatePicker
                  disabledDate={currentDate => currentDate < moment(auctionDateFrom)}
                  disabled={isFieldsDisabled}
                  onChange={value => this.onAuctionEndDateChange(value)}
                  value={auctionDateTo ? moment(auctionDateTo) : null}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div>
          <Button.Group>
            <Button onClick={this.onClear}>Clear Fields</Button>
            <Button type="primary" onClick={this.onSearch}>
              Find
            </Button>
          </Button.Group>
        </div>
      </React.Fragment>
    );
  }
}
