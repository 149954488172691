import { Request } from 'shared/tools/request';
import { createAction } from 'redux-actions';
import { createSuccessAction } from 'shared/helpers/create-success-action';

export const getDashboardData = createAction('GET_DASHBOARD_DATA');
export const getDashboardDataError = createAction('GET_FULL_AUCTIONS_LIST_ERROR');

export const setToInitialState = createAction('DASHBOARD_SET_TO_INITIAL_STATE');

export const getDashboardDataSuccess = createAction('GET_DASHBOARD_DATA_SUCCESS');

export const loadDashboardData = () => async dispatch => {
  dispatch(getDashboardData());

  try {
    const { data } = await Request.get('/api/dashboard/dashboard-data');

    dispatch(getDashboardDataSuccess(data));
  } catch (err) {
    dispatch(getDashboardDataError());
  }
};

export const loadInitialData = () => async dispatch => {
  await dispatch(loadDashboardData());
};
