const validationErrors = require('./validation-errors');

function preprocess() {
  delete validationErrors.empty_aid;
  delete validationErrors.empty_aw_name;
  validationErrors.an_not_found = 'Artist name not found in description';
  return validationErrors;
}

module.exports = preprocess();
