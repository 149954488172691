import { loadInitialData, setToInitialState } from 'system-status/actions/system-status';

import SystemStatus from 'system-status/components/SystemStatus';
import { connect } from 'react-redux';

const stateToProps = state => {
  const { systemStatusData } = state.systemStatus;

  return {
    systemStatusData,
  };
};

const dispatchToProps = {
  loadInitialData,
  setToInitialState,
};

export default connect(stateToProps, dispatchToProps)(SystemStatus);
