import { isNull } from 'lodash';

export const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

export const getNotNullFields = (fields = {}) => {
  const notNullKeys = Object.keys(fields).filter(key => !isNull(fields[key]));

  return notNullKeys.reduce(
    (obj, key) => ({
      ...obj,
      [key]: fields[key],
    }),
    {},
  );
};

export const removeEmptyFields = (fields = {}) =>
  Object.keys(fields)
    .filter(key => key !== 'artist_data' && key !== 'review_required_reason')
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: fields[key] || null,
      }),
      {},
    );
