import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { userIsAuthenticated } from 'shared/tools/auth';
import withQueryParams from 'shared/tools/withQueryParams';
import CleaningUI from 'cleaning-lots/containers/CleaningUI';
import AdminCleaningUI from 'cleaning-lots/containers/AdminCleaningUI';

export default () => [
  <Route
    key="/cleaning-lots/"
    exact
    path="/cleaning-lots/"
    render={({ location }) => <Redirect to={`/cleaning-lots/cleaning-ui${location.search}`} />}
  />,
  <Route
    key="/cleaning-lots/cleaning-ui"
    path="/cleaning-lots/cleaning-ui"
    component={userIsAuthenticated(withQueryParams(CleaningUI))}
  />,
  <Route
    key="/cleaning-lots/admin-cleaning-ui"
    path="/cleaning-lots/admin-cleaning-ui"
    component={userIsAuthenticated(AdminCleaningUI)}
  />,
];
