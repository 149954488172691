export const FIELDS = [
  {
    placeholder: 'ID',
    name: 'id',
  },
  {
    placeholder: 'Auction Name',
    name: 'auction_name',
  },
  {
    placeholder: 'Auction House Name',
    name: 'auction_house_name',
  },
  {
    placeholder: 'Auction Location',
    name: 'location',
  },
  {
    placeholder: 'Auction Number',
    name: 'number',
  },
];
