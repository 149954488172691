import { Request } from 'shared/tools/request';
import { createAction } from 'redux-actions';

export const getSystemStatusData = createAction('GET_SYSTEM_STATUS_DATA');
export const getSystemStatusDataError = createAction('GET_FULL_AUCTIONS_LIST_ERROR');

export const setToInitialState = createAction('SYSTEM_STATUS_SET_TO_INITIAL_STATE');

export const getSystemStatusDataSuccess = createAction('GET_SYSTEM_STATUS_DATA_SUCCESS');

export const loadSystemStatusData = () => async dispatch => {
  dispatch(getSystemStatusData());

  try {
    const { data } = await Request.get('/api/system-status/system-status');

    dispatch(getSystemStatusDataSuccess(data));
  } catch (err) {
    dispatch(getSystemStatusDataError());
  }
};

export const loadInitialData = () => async dispatch => {
  await dispatch(loadSystemStatusData());
};
