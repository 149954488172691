import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const CustomRow = ({ children }) => <div className="custom-row">{children}</div>;

CustomRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CustomRow;
