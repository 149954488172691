// import { compose } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  getLots,
  setActiveAuction,
  crawlAuctionPrices,
  recrawlAuction,
  getPendingJobsCount,
} from 'cleaning-lots/actions/cleaning';
import { AuctionsDropdown } from 'cleaning-lots/components/CleaningUI/AuctionsDropdown';

import history from 'shared/tools/history';

const stateToProps = state => ({
  auctions: state.auctions.fullList,
  activeAuctionId: state.lots.activeAuctionId,
  pendingJobsCount: state.lots.pendingJobsCount,
  isAdmin: state.user.userInfo.role.name === 'admin',
});

const dispatchToProps = dispatch => ({
  selectAuction: auctionId => {
    history.push({
      search: queryString.stringify({
        rawAuctionId: auctionId,
      }),
    });
    dispatch(setActiveAuction(auctionId));
    dispatch(getLots());
  },
  crawlAuctionPrices: (auctionId, selectedIds, unselectedIds, isAllSelected) => {
    dispatch(crawlAuctionPrices(auctionId, selectedIds, unselectedIds, isAllSelected));
  },
  recrawlAuction: (auctionId, selectedIds, unselectedIds, isAllSelected) => {
    dispatch(recrawlAuction(auctionId, selectedIds, unselectedIds, isAllSelected));
  },
  getPendingJobsCount: auctionId => {
    dispatch(getPendingJobsCount(auctionId));
  },
});

export default connect(stateToProps, dispatchToProps)(AuctionsDropdown);
