import { denormalizeAndMapItems, denormalizeRows } from 'cleaning-lots/normalization/denormalize';
import {
  getAuction,
  ignoreLots,
  openEditModal as openEditPriorityModal,
  setPage,
  setSelectedAuctions,
  setSortBy,
  setSortDirection,
  setToInitialState,
  unpublishLots,
} from 'cleaning-lots/actions/admin-cleaning';

import AdminCleaningUI from 'cleaning-lots/components/AdminCleaningUI';
import { connect } from 'react-redux';
import { openNotesModal } from 'cleaning-lots/actions/notes';
import { openPublishModal } from 'cleaning-lots/actions/publish';
import { openUnpublishModal } from 'cleaning-lots/actions/unpublish';

const getColumns = state =>
  state.user.userInfo.role.name === 'admin'
    ? state.auctions.columns
    : state.auctions.columns.filter(c => !c.isHideForCleaner);

const calculateTableWidth = columns =>
  columns.reduce((totalWidth, column) => totalWidth + column.width, 0);

const stateToProps = state => {
  const columns = getColumns(state);

  return {
    auctionRows: denormalizeAndMapItems(state.auctions, state.cleaners),
    cleaners: denormalizeRows(state.cleaners),
    auctions: state.auctions,
    count: state.auctions.count,
    currentPage: state.auctions.currentPage,
    columns,
    selectedIds: state.auctions.selectedIds,
    isLoading: state.auctions.isLoading,
    isAdmin: state.user.userInfo.role.name === 'admin',
    isSuperAdmin: state.user.userInfo.email.includes('superadmin'),
    sortBy: state.auctions.sortBy,
    sortDir: state.auctions.sortDir,
    tableWidth: calculateTableWidth(columns),
  };
};

const dispatchToProps = {
  getAuction,
  unpublishLots,
  ignoreLots,
  // finishCleaning,
  setPage,
  setSelectedAuctions,
  setToInitialState,
  openEditPriorityModal,
  openPublishModal,
  openUnpublishModal,
  openNotesModal,
  setSortDirection,
  setSortBy,
};

export default connect(stateToProps, dispatchToProps)(AdminCleaningUI);
