import { Record } from 'immutable';
import { get } from 'lodash';

import Role from './Role';

export default class User extends Record({
  id: null,
  email: null,
  name: null,
  surname: null,
  apiToken: null,
  role: null,
}) {
  static parse(userInfo) {
    return new User({
      id: get(userInfo, 'id', ''),
      email: get(userInfo, 'email', ''),
      name: get(userInfo, 'name', ''),
      surname: get(userInfo, 'surname', ''),
      apiToken: get(userInfo, 'api_token', ''),
      role: new Role({
        id: userInfo.role.id,
        name: userInfo.role.name,
      }),
    });
  }
}
