import PropTypes from 'prop-types';

export const auctionModel = PropTypes.shape({
  id: PropTypes.number.isRequired,
  cleaner_id: PropTypes.string,
  auction_name: PropTypes.string,
  auction_house_name: PropTypes.string,
  date: PropTypes.string.isRequired,
  date_end: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  nbParsedLots: PropTypes.number.isRequired,
  nbParsedLotsInReview: PropTypes.number.isRequired,
  nb_of_lots_manual: PropTypes.number,
});

export const optionModel = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
  }),
);
