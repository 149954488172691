export const STORAGE_KEY = 'ciq_user';
export const APPRAISAL_ID_KEY = 'appraisal-id';
export const USER_ID_KEY = 'user-id';

export const ARTIST_STATUS = {
  PUBLISHED: 'published',
  PENDING: 'pending',
  //   DELETED: 'deleted',
};

export const MAX_UNDERESTIMATED_FACTOR = 50;
