import './index.scss';

import { AutoComplete, Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { PureComponent } from 'react';

import { ImageLoader } from 'cleaning-lots/components/CleaningUI/ImageLoader';
import PropTypes from 'prop-types';
import birthDeathTypes from '../../../../../src/utils/birth-death-types';
import { getAuthorUrl, getUlanUrl, getUlanUrlSearch } from 'shared/tools/url';
import { isNil } from 'lodash';
import nationalities from 'shared/constants/nationalities';

const { TextArea } = Input;

class EditArtistModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateArtist: PropTypes.func.isRequired,
    navigateToPrevArtist: PropTypes.func.isRequired,
    navigateToNextArtist: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isPrevButtonDisabled: PropTypes.bool.isRequired,
    isNextButtonDisabled: PropTypes.bool.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,

    artistToEdit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      qualifier: PropTypes.string,
      qualified_artist_id: PropTypes.number,
      url_thumb: PropTypes.string,
      url_full: PropTypes.string,
      lots_count: PropTypes.number,
      is_hidden: PropTypes.number,
      ulan_id: PropTypes.number,
      ulan_alternate_spellings: PropTypes.arrayOf(PropTypes.string),
      ulan_preferred_birth: PropTypes.number,
      ulan_preferred_nationality: PropTypes.string,
      ulan_alternate_nationalities: PropTypes.arrayOf(PropTypes.string),
      ulan_alternate_birth: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,

    artistQualifiers: PropTypes.arrayOf(PropTypes.string.isRequired),
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAdmin: PropTypes.bool.isRequired,

    getUlanData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    artistQualifiers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      nationalities,
    };
  }

  onOk = () => {
    const {
      form: { validateFieldsAndScroll },
      artistToEdit,
      updateArtist,
    } = this.props;

    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        updateArtist(artistToEdit.id, fields);
      }
    });
  };

  navigateToArtist = navigateCallback => () => {
    const {
      form: { resetFields },
    } = this.props;

    resetFields();
    navigateCallback();
  };

  getArtistQualifiers = () => {
    const { qualifier } = this.props.artistToEdit;

    const { artistQualifiers } = this.props;

    if (!qualifier || (qualifier && artistQualifiers.includes(qualifier))) {
      return artistQualifiers;
    }

    return [qualifier, ...artistQualifiers];
  };

  filterNationalities = text => {
    if (!text) {
      this.setState({ nationalities });
    }
    let nationalitiesCopy = [...nationalities];
    nationalitiesCopy = nationalitiesCopy.filter(n => n.toLowerCase().includes(text.toLowerCase()));
    this.setState({ nationalities: nationalitiesCopy });
  };

  getFieldValue = fieldName => {
    const formValues = this.props.form.getFieldsValue();
    return formValues[fieldName];
  };

  syncUlanData = () => {
    const ulanId = this.getFieldValue('ulan_id');
    this.props.getUlanData(ulanId, this.props.form.getFieldsValue());
  };

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      artistToEdit,
      visible,
      closeModal,
      navigateToPrevArtist,
      navigateToNextArtist,
      isLoading,
      isPrevButtonDisabled,
      isNextButtonDisabled,
    } = this.props;
    const title = (
      <div className="edit-artist__title">
        <Button
          type="primary"
          shape="circle"
          icon="left"
          onClick={this.navigateToArtist(navigateToPrevArtist)}
          disabled={isPrevButtonDisabled}
        />
        <span>Edit Artist</span>
        <Button
          type="primary"
          shape="circle"
          icon="right"
          onClick={this.navigateToArtist(navigateToNextArtist)}
          disabled={isNextButtonDisabled}
        />
      </div>
    );

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !isFieldsTouched() || isLoading,
          onClick: this.onOk,
        }}
        width="50vw"
        bodyStyle={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        {isLoading ? (
          <div className="edit-artist__spin">
            <Spin />
          </div>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item label="Artist Image">
                  {getFieldDecorator('url_full', {
                    initialValue: {
                      fileList: artistToEdit.url_full
                        ? [{ url: artistToEdit.url_full, status: 'done', uid: 1 }]
                        : [],
                    },
                  })(<ImageLoader accept="image/*" width="100%" />)}
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item label="Name">
                  {getFieldDecorator('name', {
                    initialValue: artistToEdit.name,
                  })(<Input />)}
                  <Button href={getAuthorUrl(artistToEdit.id)} target="blank">
                    View Artist
                  </Button>
                </Form.Item>

                {this.props.isAdmin && (
                  <Form.Item label="Hidden">
                    {getFieldDecorator('is_hidden', {
                      initialValue: artistToEdit.is_hidden ? 'Yes' : 'No',
                    })(
                      <Select>
                        <Select.Option key="No" value="No">
                          No
                        </Select.Option>
                        <Select.Option key="Yes" value="Yes">
                          Yes
                        </Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                )}

                {this.props.isAdmin && (
                  <Form.Item label="Status">
                    {getFieldDecorator('artist_status', {
                      initialValue: artistToEdit.artist_status,
                    })(
                      <Select>
                        {this.props.statuses.map(status => (
                          <Select.Option key={status} value={status}>
                            {status}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                )}

                <Form.Item label="Qualifier">
                  {getFieldDecorator('qualifier', {
                    initialValue: artistToEdit.qualifier ? artistToEdit.qualifier : null,
                    rules: [
                      {
                        required: this.getFieldValue('qualified_artist_id'),
                        message: 'Artist Qualifier required with Qualified Artist ID',
                      },
                    ],
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {this.getArtistQualifiers().map(qualifier => (
                        <Select.Option key={qualifier} value={qualifier}>
                          {qualifier}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Qualified Artist Id">
                  {getFieldDecorator('qualified_artist_id', {
                    initialValue: artistToEdit.qualified_artist_id,
                    rules: [
                      {
                        required: this.getFieldValue('qualifier'),
                        message: 'Qualified Artist ID required with Qualifier',
                      },
                    ],
                  })(<Input />)}

                  {!isNil(artistToEdit.qualified_artist_id) && (
                    <Button href={getAuthorUrl(artistToEdit.qualified_artist_id)} target="blank">
                      View Qualified Artist
                    </Button>
                  )}
                </Form.Item>
                <Form.Item label="Nationality">
                  {getFieldDecorator('nationality', {
                    initialValue: artistToEdit.nationality,
                  })(
                    <AutoComplete
                      disabled={artistToEdit.qualified_artist_id}
                      dataSource={this.state.nationalities}
                      onSearch={this.filterNationalities}
                      placeholder="Nationality"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Birth / Established">
                  {getFieldDecorator('birth', {
                    initialValue: artistToEdit.birth,
                  })(<Input type="number" disabled={artistToEdit.qualified_artist_id} />)}
                </Form.Item>
                <Form.Item label="Death">
                  {getFieldDecorator('death', {
                    initialValue: artistToEdit.death,
                  })(<Input type="number" disabled={artistToEdit.qualified_artist_id} />)}
                </Form.Item>
                <Form.Item label="Birth/Death type">
                  {getFieldDecorator('birth_death_type', {
                    initialValue: artistToEdit.birth_death_type
                      ? artistToEdit.birth_death_type
                      : null,
                  })(
                    <Select>
                      {Object.keys(birthDeathTypes).map(value => (
                        <Select.Option key={value} value={value}>
                          {birthDeathTypes[value]}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alternate Spellings">
                  {getFieldDecorator('alternate_spellings', {
                    initialValue: artistToEdit.alternate_spellings,
                  })(
                    <TextArea
                      autosize={{ minRows: 1, maxRows: 4 }}
                      disabled={artistToEdit.qualified_artist_id}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Active Date">
                  {getFieldDecorator('active_date', {
                    initialValue: artistToEdit.active_date,
                  })(<Input maxLength={120} />)}
                </Form.Item>

                <Form.Item label="Alternate DoB">
                  {getFieldDecorator('alternate_birth', {
                    initialValue: artistToEdit.alternate_birth,
                  })(<Input type="number" disabled={artistToEdit.qualified_artist_id} />)}
                </Form.Item>

                <Form.Item label="ULAN ID">
                  {getFieldDecorator('ulan_id', {
                    initialValue: artistToEdit.ulan_id,
                  })(<Input />)}

                  <Button onClick={this.syncUlanData}>Sync Data</Button>

                  <Button
                    href={getUlanUrl(artistToEdit.ulan_id)}
                    target="blank"
                    disabled={!artistToEdit.ulan_id}
                  >
                    View Artist
                  </Button>

                  <Button href={getUlanUrlSearch(artistToEdit.name)} target="blank">
                    Search Artist
                  </Button>
                </Form.Item>

                <Form.Item label="ULAN Alternate Spellings">
                  {getFieldDecorator('ulan_alternate_spellings', {
                    initialValue: artistToEdit.ulan_alternate_spellings
                      ? artistToEdit.ulan_alternate_spellings.join('\n')
                      : '',
                  })(<TextArea disabled autosize={{ minRows: 1, maxRows: 6 }} />)}
                </Form.Item>

                <Form.Item label="ULAN Preferred DoB">
                  {getFieldDecorator('ulan_preferred_birth', {
                    initialValue: artistToEdit.ulan_preferred_birth,
                  })(<Input disabled />)}
                </Form.Item>

                <Form.Item label="ULAN Preferred Nationality">
                  {getFieldDecorator('ulan_preferred_nationality', {
                    initialValue: artistToEdit.ulan_preferred_nationality,
                  })(<Input disabled />)}
                </Form.Item>

                <Form.Item label="ULAN Alternate DoB">
                  {getFieldDecorator('ulan_alternate_birth', {
                    initialValue: artistToEdit.ulan_alternate_birth
                      ? artistToEdit.ulan_alternate_birth.join('\n')
                      : '',
                  })(<TextArea disabled autosize={{ minRows: 1, maxRows: 6 }} />)}
                </Form.Item>

                <Form.Item label="ULAN Alternate Nationalities">
                  {getFieldDecorator('ulan_alternate_nationalities', {
                    initialValue: artistToEdit.ulan_alternate_nationalities
                      ? artistToEdit.ulan_alternate_nationalities.join('\n')
                      : '',
                  })(<TextArea disabled autosize={{ minRows: 1, maxRows: 6 }} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

export default EditArtistModal;
