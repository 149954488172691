import { createAction } from 'redux-actions';
import { Request } from 'shared/tools/request';
import { notification } from 'antd';

import { PUBLISH_TYPE } from 'cleaning-lots/constants/publish';

import { getAuction } from './admin-cleaning';

const openPublishModal = createAction('OPEN_PUBLISH_MODAL');
const closePublishModal = createAction('CLOSE_PUBLISH_MODAL');

const openNotification = (type, message, description) => {
  notification[type]({ message, description });
};

const publish = (selectedIds, publishType, mediumParserConfidence) => async dispatch => {
  try {
    if (publishType === PUBLISH_TYPE.ALL_LOTS) {
      await Request.put('/api/raw-auction/publish/all-lots', {
        data: {
          rawAuctionIds: selectedIds,
        },
      });
    }

    if (publishType === PUBLISH_TYPE.ALL_REVIEWED_LOTS) {
      await Request.put('/api/raw-auction/publish', {
        data: {
          ids: selectedIds,
        },
      });
    }

    if (publishType === PUBLISH_TYPE.ALL_LOTS_WITH_MEDIUM_PARSER_CONFIDENCE) {
      await Request.put('/api/raw-auction/publish/by-confidence', {
        data: {
          rawAuctionIds: selectedIds,
          mediumParserConfidence,
        },
      });
    }

    dispatch(closePublishModal());
    dispatch(getAuction());
  } catch (err) {
    openNotification('error', 'Error has been occurred while publishing lots');
  }
};

export { openPublishModal, closePublishModal, publish };
