import { schema, normalize } from 'normalizr';

const itemsEntity = new schema.Entity(
  'items',
  {},
  {
    idAttribute: 'id',
  },
);

const rowsSchema = {
  rows: [itemsEntity],
};

export const normalizeRows = data => normalize(data, rowsSchema);
