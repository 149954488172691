import React from 'react';
import queryString from 'query-string';

const withQueryParams = Component => {
  return props => {
    const queryParams = queryString.parse(props.location.search);

    return <Component {...props} queryParams={queryParams} />;
  };
};

export default withQueryParams;
