import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { closeNotesModal, setNotes } from 'cleaning-lots/actions/notes';
import EditNotesModal from 'cleaning-lots/components/AdminCleaningUI/EditNotesModal';

const stateToProps = state => ({
  isVisible: state.notes.isNotesModalOpened,
});

const dispatchToProps = {
  setNotes,
  closeModal: closeNotesModal,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(EditNotesModal);
