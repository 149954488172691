import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Form } from 'antd';

class AssignCleanerModal extends PureComponent {
  static propTypes = {
    assignCleaner: PropTypes.func.isRequired,

    closeModal: PropTypes.func.isRequired,

    visible: PropTypes.bool.isRequired,

    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    cleaners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
    ).isRequired,
  };

  assignCleaner = async () => {
    const cleanerId = this.props.form.getFieldValue('cleaner_id') || null;
    await this.props.assignCleaner(this.props.selectedIds, cleanerId);
    this.props.closeModal();
  };

  render() {
    const {
      form: { getFieldDecorator },
      cleaners,
    } = this.props;
    return (
      <Modal
        title="Assign Cleaner"
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        onOk={this.assignCleaner}
      >
        <Form>
          <Form.Item label="Cleaner">
            {getFieldDecorator('cleaner_id', {
              initialValue: '',
            })(
              <Select>
                <Select.Option value="">None</Select.Option>
                {cleaners.map(cleaner => (
                  <Select.Option key={cleaner.id} value={cleaner.id}>
                    {`${cleaner.name} ${cleaner.surname}`}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export { AssignCleanerModal };
