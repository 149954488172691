import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { mergeArtist } from 'artist/actions/artist-page';

import MergeArtistModal from 'artist/components/merge-modal/index';

const stateToProps = state => ({
  isAdmin: state.user.userInfo.role.name === 'admin',
  isLoading: state.artists.isArtistCreating,
});

const dispatchToProps = dispatch => ({
  createArtist: fields => {
    dispatch(mergeArtist(fields));
  },
});

export default compose(connect(stateToProps, dispatchToProps), Form.create())(MergeArtistModal);
