import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

class DeleteModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    deleteAuctions: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  deleteAuctions = async () => {
    await this.props.deleteAuctions(this.props.selectedIds);
    this.props.closeModal();
  };

  render() {
    const title = `Delete Auction${this.props.selectedIds.length > 1 ? 's' : ''}`;
    const question = `Are you sure you want to delete auction${
      this.props.selectedIds.length > 1 ? 's?' : '?'
    }`;
    return (
      <Modal
        title={title}
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        onOk={this.deleteAuctions}
      >
        <p>
          <b>This action cannot be undone</b>
        </p>
        <p>{question}</p>
      </Modal>
    );
  }
}

export { DeleteModal };
