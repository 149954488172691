import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { updateAuction } from 'cleaning-lots/actions/admin-cleaning';
import EditAuctionModal from 'cleaning-lots/components/AdminCleaningUI/EditAuctionModal';
import { removeEmptyFields } from 'shared/tools/form';
import { formatDate } from 'shared/helpers/format';
import { formattedStatuses, formattedPriorities } from 'cleaning-lots/constants/raw-auction';
import { denormalizeRows } from '../normalization/denormalize';

const formatCleaners = cleaners =>
  cleaners.map(cleaner => ({
    key: cleaner.id,
    value: cleaner.id,
    label: `${cleaner.name} ${cleaner.surname}`,
  }));

const stateToProps = state => ({
  cleaners: formatCleaners(denormalizeRows(state.cleaners)),
  priorities: formattedPriorities,
  auctionStatuses: formattedStatuses,
  isAdmin: state.user.userInfo.role.name === 'admin',
});

const dispatchToProps = dispatch => ({
  updateAuction: (id, fields) => {
    if (fields.date) {
      fields.date = formatDate(fields.date);
    }
    if (fields.date_end) {
      fields.date_end = formatDate(fields.date_end);
    }

    dispatch(updateAuction(id, removeEmptyFields(fields)));
  },
});

export default compose(connect(stateToProps, dispatchToProps), Form.create())(EditAuctionModal);
