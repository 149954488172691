import { Form, Modal, Select } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';
import { UNPUBLISH_TYPE } from 'cleaning-lots/constants/unpublish';
import _ from 'lodash';

const UNPUBLISH_TYPE_LABELS = {
  [UNPUBLISH_TYPE.ALL_LOTS]: 'All lots',
  [UNPUBLISH_TYPE.ALL_IGNORED_LOTS]: 'All ignored lots',
  [UNPUBLISH_TYPE.ALL_LP_IGNORED_LOTS]: 'LP ignored lots',
};

const UnpublishModal = ({ selectedIds, unpublish, isVisible, closeModal, form }) => {
  console.log(selectedIds, isVisible)

  const { getFieldDecorator, getFieldValue } = form;

  const onOk = () => {
    const unpublishType = getFieldValue('unpublishType') || null;
    const mediumParserConfidence = getFieldValue('mediumParserConfidence');

    unpublish(selectedIds, unpublishType, mediumParserConfidence);
  };

  return (
    <Modal title="Unpublish" visible={isVisible} onOk={onOk} onCancel={closeModal}>
      <Form>
        <Form.Item label="Unpublish type">
          {getFieldDecorator('unpublishType', {
            initialValue: UNPUBLISH_TYPE.ALL_LOTS,
          })(
            <Select>
              {_.map(UNPUBLISH_TYPE_LABELS, (value, key) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

UnpublishModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
  }).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,

  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,

  unpublish: PropTypes.func.isRequired,
};

export default UnpublishModal;
