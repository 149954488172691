import {
  closeEditModal,
  getAuctionError,
  getAuctionRequest,
  getAuctionSuccess,
  getFullAuctionsListError,
  getFullAuctionsListRequest,
  getFullAuctionsListSuccess,
  openEditModal,
  sendLotsActionError,
  sendLotsActionRequest,
  sendLotsActionSuccess,
  setAuctionDateFrom,
  setAuctionDateTo,
  setAuctionFlag,
  setAuctionHouse,
  setAuctionStatus,
  setCreatedDateFrom,
  setCreatedDateTo,
  setFilters,
  setPage,
  setPublishedDateFrom,
  setPublishedDateTo,
  setSelectedAuctions,
  setSortBy,
  setSortDirection,
  setToInitialState,
  updateAuctionError,
  updateAuctionRequest,
  updateAuctionSuccess,
} from 'cleaning-lots/actions/admin-cleaning';
import {
  errorProducer,
  receiveDataProducer,
  setFiltersProducer,
  setPageProducer,
  setSelectedIds,
  successProducer,
} from 'shared/helpers/producers';

import { adminCleaningUiColumns } from 'cleaning-lots/constants/table-columns';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const initState = {
  isLoading: false,
  success: false,
  count: 0,
  ids: [],
  items: {},
  fullList: [],
  filters: {},
  offset: 0,
  currentPage: 1,
  columns: adminCleaningUiColumns,
  isEditModalVisible: false,
  sortDir: 'desc',
  sortBy: 'id',
};

export const auctionsReducer = handleActions(
  {
    [setToInitialState]: produce(draft => {
      draft.isLoading = false;
      draft.success = false;
      draft.count = 0;
      draft.ids = [];
      draft.items = {};
      draft.filters = {};
      draft.offset = 0;
      draft.currentPage = 1;
      draft.fullList = [];
      draft.sortDir = 'desc';
      draft.sortBy = 'id';
    }),
    [getAuctionRequest]: produce(draft => {
      draft.isLoading = true;
      draft.selectedIds = [];
    }),
    [getAuctionSuccess]: produce(receiveDataProducer),
    [getAuctionError]: produce(errorProducer),
    [setSelectedAuctions]: produce(setSelectedIds),
    [sendLotsActionRequest]: produce(draft => {
      draft.isLoading = true;
      draft.selectedIds = [];
    }),
    [sendLotsActionSuccess]: produce(successProducer),
    [sendLotsActionError]: produce(errorProducer),
    [getFullAuctionsListRequest]: produce(draft => {
      draft.isLoading = true;
      draft.fullList = [];
    }),
    [getFullAuctionsListSuccess]: produce((draft, action) => {
      draft.success = true;
      draft.isLoading = false;
      draft.fullList = action.payload.items;
    }),
    [getFullAuctionsListError]: produce(errorProducer),
    [updateAuctionRequest]: produce(draft => {
      draft.isLoading = true;
    }),
    [updateAuctionSuccess]: produce(successProducer),
    [updateAuctionError]: produce(errorProducer),
    [setFilters]: produce(setFiltersProducer),
    [setPage]: produce(setPageProducer),
    [openEditModal]: produce(draft => {
      draft.isEditModalVisible = true;
    }),
    [closeEditModal]: produce(draft => {
      draft.isEditModalVisible = false;
    }),
    [setSortDirection]: produce((draft, action) => {
      draft.sortDir = action.payload.sortDir;
    }),
    [setSortBy]: produce((draft, action) => {
      draft.sortBy = action.payload.sortBy;
    }),
    [setAuctionHouse]: produce((draft, action) => {
      draft.auctionHouseId = action.payload.auctionHouseId;
    }),
    [setCreatedDateFrom]: produce((draft, action) => {
      draft.createdDateFrom = action.payload.createdDateFrom;
    }),
    [setCreatedDateTo]: produce((draft, action) => {
      draft.createdDateTo = action.payload.createdDateTo;
    }),
    [setPublishedDateFrom]: produce((draft, action) => {
      draft.publishedDateFrom = action.payload.publishedDateFrom;
    }),
    [setPublishedDateTo]: produce((draft, action) => {
      draft.publishedDateTo = action.payload.publishedDateTo;
    }),
    [setAuctionStatus]: produce((draft, action) => {
      draft.auctionStatus = action.payload.auctionStatus;
    }),
    [setAuctionFlag]: produce((draft, action) => {
      draft.auctionFlag = action.payload.auctionFlag;
    }),
    [setAuctionDateFrom]: produce((draft, action) => {
      draft.auctionDateFrom = action.payload.auctionDateFrom;
    }),
    [setAuctionDateTo]: produce((draft, action) => {
      draft.auctionDateTo = action.payload.auctionDateTo;
    }),
  },
  {
    ...initState,
    selectedIds: [],
  },
);
