import { closeUnpublishModal, openUnpublishModal } from 'cleaning-lots/actions/unpublish';

import { handleActions } from 'redux-actions';
import produce from 'immer';

const initState = {
  isUnpublishModalOpened: false,
};

const unpublishReducer = handleActions(
  {
    [openUnpublishModal]: produce(draft => {
      draft.isUnpublishModalOpened = true;
    }),
    [closeUnpublishModal]: produce(draft => {
      draft.isUnpublishModalOpened = false;
    }),
  },
  initState,
);

export { unpublishReducer };
