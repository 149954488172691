import { createAction } from 'redux-actions';
import { Request } from 'shared/tools/request';
import { createSuccessAction } from 'shared/helpers/create-success-action';
import { normalizeRows } from '../normalization/cleaners-schema';

export const getCleanersRequest = createAction('CLEANERS_REQUEST');
export const getCleanersSuccess = createSuccessAction('CLEANERS_SUCCESS');
export const getCleanersError = createAction('CLEANERS_ERROR');

export const getCleaners = () => async (dispatch, getState) => {
  const isCleanersLoaded = getState().cleaners.success;

  if (isCleanersLoaded) {
    return;
  }

  dispatch(getCleanersRequest());
  try {
    const { data } = await Request.get('/api/users/cleaners');
    const {
      entities: { items },
      result: { count, rows },
    } = normalizeRows(data);
    dispatch(getCleanersSuccess(items, rows, count));
  } catch (err) {
    dispatch(getCleanersError());
  }
};
