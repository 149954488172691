import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { createArtist } from 'artist/actions/artist-page';

import CreateArtistModal from 'artist/components/create-modal/index';

import { removeEmptyFields } from 'shared/tools/form';
import { ARTIST_STATUS } from 'shared/constants/app';

const ARTIST_STATUSES = Object.values(ARTIST_STATUS);

const stateToProps = state => ({
  artistQualifiers: state.artistQualifier.items.map(x => x.name),
  statuses: ARTIST_STATUSES,
  isAdmin: state.user.userInfo.role.name === 'admin',
  isLoading: state.artists.isArtistCreating,
});

const dispatchToProps = dispatch => ({
  createArtist: fields => {
    dispatch(createArtist(removeEmptyFields(fields)));
  },
});

export default compose(connect(stateToProps, dispatchToProps), Form.create())(CreateArtistModal);
