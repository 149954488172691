import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Form, Input, InputNumber, Button, Row, Col, Spin, Popover } from 'antd';
import { ImageLoader } from 'cleaning-lots/components/CleaningUI/ImageLoader';
import { isNil, get } from 'lodash';

import { getMediumColorByParserConfidence } from 'shared/tools/medium';

import { getAuthorUrl } from 'shared/tools/url';

import {
  cleanerReviewStatuses,
  cleanerReviewStatusesForPendingArtist,
  currencies,
  measurements,
  lotModel,
  mediumFinals,
} from 'cleaning-lots/constants/lot-fields';

import { ARTIST_STATUS } from 'shared/constants/app';

import './index.scss';

const { TextArea } = Input;

const getArtistInfo = artist => {
  if (!artist) {
    return null;
  }

  let statusColor = '';

  switch (artist.artist_status) {
    case ARTIST_STATUS.PUBLISHED:
      statusColor = 'green';
      break;
    case ARTIST_STATUS.PENDING:
      statusColor = 'orange';
      break;
    default:
      statusColor = '';
  }

  return (
    <React.Fragment>
      <p>
        <b>Status: </b>
        <span style={{ color: statusColor }}>{artist.artist_status}</span>
      </p>
      <p>
        <b>Lots Count: </b>
        {artist.lots_count}
      </p>
    </React.Fragment>
  );
};

class EditLotModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateLot: PropTypes.func.isRequired,
    navigateToPrevLot: PropTypes.func.isRequired,
    navigateToNextLot: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isPrevButtonDisabled: PropTypes.bool.isRequired,
    isNextButtonDisabled: PropTypes.bool.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    lotToEdit: lotModel.isRequired,

    artistQualifiers: PropTypes.arrayOf(PropTypes.string.isRequired),
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    artistQualifiers: [],
  };

  onOk = () => {
    const {
      form: { validateFieldsAndScroll },
      lotToEdit,
      updateLot,
    } = this.props;
    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        updateLot(lotToEdit.raw_lot_id, fields);
      }
    });
  };

  navigateToLot = navigateCallback => () => {
    const {
      form: { resetFields },
    } = this.props;
    resetFields();
    navigateCallback();
  };

  getArtistQualifiers = () => {
    const qualifier = this.props.lotToEdit.artist_qualifier;
    const { artistQualifiers } = this.props;

    if (!qualifier || (qualifier && artistQualifiers.includes(qualifier))) {
      return artistQualifiers;
    }

    return [qualifier, ...artistQualifiers];
  };

  getCleanerReviewStatuses = () => {
    const artistStatus = get(this.props.lotToEdit, ['artist', 'artist_status']); 
    const isArtistPending = artistStatus === ARTIST_STATUS.PENDING;

    return this.props.isAdmin || !isArtistPending ? cleanerReviewStatuses : cleanerReviewStatusesForPendingArtist;
  };

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      lotToEdit,
      visible,
      closeModal,
      navigateToPrevLot,
      navigateToNextLot,
      isLoading,
      isPrevButtonDisabled,
      isNextButtonDisabled,
    } = this.props;

    const modalTitle = (
      <div className="edit-lot__title">
        <Button
          type="primary"
          shape="circle"
          icon="left"
          onClick={this.navigateToLot(navigateToPrevLot)}
          disabled={isPrevButtonDisabled}
        />
        <span>Edit Lot</span>
        <Button
          type="primary"
          shape="circle"
          icon="right"
          onClick={this.navigateToLot(navigateToNextLot)}
          disabled={isNextButtonDisabled}
        />
      </div>
    );

    const mediumLabel = (
      <span>
        Medium Final
        <span
          style={{
            color: getMediumColorByParserConfidence(lotToEdit.medium_parser_confidence),
          }}
        >
          {!lotToEdit.medium_parser_confidence
            ? null
            : ` ${lotToEdit.medium_parser} (${lotToEdit.medium_parser_confidence}%)`}
        </span>
      </span>
    );

    return (
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !isFieldsTouched(),
          onClick: this.onOk,
        }}
        width="80vw"
        bodyStyle={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        {isLoading ? (
          <div className="edit-lot__spin">
            <Spin />
          </div>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <Row gutter={24}>
              <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Lot Image">
                  {getFieldDecorator('lot_image_url', {
                    initialValue: {
                      fileList: lotToEdit.lot_image_url
                        ? [{ url: lotToEdit.lot_image_url, status: 'done', uid: 1 }]
                        : [],
                    },
                  })(<ImageLoader accept="image/*" width="100%" />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 4 }} md={{ span: 4 }} xs={{ span: 12 }}>
                {lotToEdit.lot_origin_url && (
                  <Button href={lotToEdit.lot_origin_url} target="blank">
                    Lot Origin Url
                  </Button>
                )}
              </Col>
              <Col xl={{ span: 5 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Cleaner Review Status">
                  {getFieldDecorator('cleaner_review_status', {
                    initialValue: lotToEdit.cleaner_review_status ? lotToEdit.cleaner_review_status : null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {this.getCleanerReviewStatuses().map(reviewStatus => (
                        <Select.Option key={reviewStatus} value={reviewStatus}>
                          {reviewStatus}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Review Required/Reason">
                  {getFieldDecorator('review_required_reason', {
                    initialValue: `${lotToEdit.review_required ? 'Yes' : 'No'} - ${lotToEdit.review_required_reason ||
                      'N/A'}`,
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Artist ID">
                  {getFieldDecorator('artist_id', {
                    initialValue: lotToEdit.artist_id ? lotToEdit.artist_id : null,
                  })(<Input disabled />)}
                </Form.Item>

                {!isNil(lotToEdit.artist_id) && (
                  <React.Fragment>
                    <Popover
                      title={lotToEdit.artist ? lotToEdit.artist.name : ''}
                      content={getArtistInfo(lotToEdit.artist)}
                    >
                      <Button href={getAuthorUrl(lotToEdit.artist_id)} target="blank" style={{ height: '100%' }}>
                                                {'View Artist'}
                                                
                        <br />
                        <b
                          style={{
                            color:
                              lotToEdit.artist && lotToEdit.artist.artist_status === ARTIST_STATUS.PENDING
                                ? 'orange'
                                : '',
                          }}
                        >
                          {` ${lotToEdit.artist ? lotToEdit.artist.name : ''}`}
                                                </b>
                                                <p> <b>Nationality: </b>{lotToEdit.artist.nationality}</p>
                                                <p> <b>Birth: </b>{lotToEdit.artist.birth}</p>
                                                <p> <b>Death: </b>{lotToEdit.artist.death}</p>
                                                <p> <b>High Priority: </b>{lotToEdit.artist ? (lotToEdit.artist.is_high_priority ? <span style={{color: 'green'}}>Yes</span> : 'No') : ''}</p>
                                                <p> <b>Hidden: </b>{lotToEdit.artist ? (lotToEdit.artist.is_hidden ? <span style={{color: 'red'}}>Yes</span> : 'No') : ''}</p>
                     </Button>
                                           
                    </Popover>
                  </React.Fragment>
                )}
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Classified Artist ID">
                  {getFieldDecorator('classified_artist_id', {
                    initialValue: lotToEdit.classified_artist_id ? lotToEdit.classified_artist_id : null,
                  })(<Input />)}
                </Form.Item>

                {!isNil(lotToEdit.classified_artist_id) && (
                  <Popover
                    title={lotToEdit.classified_artist ? lotToEdit.classified_artist.name : ''}
                    content={getArtistInfo(lotToEdit.classified_artist)}
                  >
                    <Button
                      href={getAuthorUrl(lotToEdit.classified_artist_id)}
                      target="blank"
                      style={{ height: '100%' }}
                    >
                      View Classified Artist
                      <br />
                      <b
                        style={{
                          color:
                            lotToEdit.classified_artist &&
                            lotToEdit.classified_artist.artist_status === ARTIST_STATUS.PENDING
                              ? 'orange'
                              : '',
                        }}
                      >
                        {` ${lotToEdit.classified_artist ? lotToEdit.classified_artist.name : ''}`}
                                            </b>
                                            <p> <b>Nationality: </b>{lotToEdit.classified_artist ? lotToEdit.classified_artist.nationality : ''}</p>
                                            <p> <b>Birth: </b>{lotToEdit.classified_artist ? lotToEdit.classified_artist.birth : ''}</p>
                                            <p> <b>Death: </b>{lotToEdit.classified_artist ? lotToEdit.classified_artist.death : ''}</p>
                                            <p> <b>High Priority: </b>{lotToEdit.classified_artist ? (lotToEdit.classified_artist.is_high_priority ? <span style={{color: 'green'}}>Yes</span> : 'No') : ''}</p>
                                            <p> <b>Hidden: </b>{lotToEdit.classified_artist ? (lotToEdit.classified_artist.is_hidden ? <span style={{color: 'red'}}>Yes</span> : 'No') : ''}</p>
                                        </Button>
                                                    </Popover>
                )}
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artist Qualifier">
                  {getFieldDecorator('artist_qualifier', {
                    initialValue: lotToEdit.artist_qualifier ? lotToEdit.artist_qualifier : null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {this.getArtistQualifiers().map(qualifier => (
                        <Select.Option key={qualifier} value={qualifier}>
                          {qualifier}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Button href="/artist">Edit Artist</Button>
              </Col>
              <Col xl={{ span: 24 }} md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item label="Parsed Artist Data">
                  {getFieldDecorator('artist_data', {
                    initialValue: lotToEdit.artist_data ? JSON.stringify(lotToEdit.artist_data) : null,
                  })(<TextArea disabled autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 12 }}>
                <Form.Item label="Lot Number">
                  {getFieldDecorator('lot_num', {
                    initialValue: lotToEdit.lot_num ? lotToEdit.lot_num : null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 8 }} md={{ span: 14 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Name">
                  {getFieldDecorator('artwork_name', {
                    initialValue: lotToEdit.artwork_name ? lotToEdit.artwork_name : null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Creation Year">
                  {getFieldDecorator('artwork_creation_year', {
                    initialValue: lotToEdit.artwork_creation_year ? lotToEdit.artwork_creation_year : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Creation Notes">
                  {getFieldDecorator('creation_notes', {
                    initialValue: lotToEdit.creation_notes ? lotToEdit.creation_notes : null,
                  })(<Input maxLength={120} />)}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }}>
                <Form.Item label="Artwork Markings">
                  {getFieldDecorator('artwork_markings', {
                    initialValue: lotToEdit.artwork_markings ? lotToEdit.artwork_markings : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Edition Current">
                  {getFieldDecorator('artwork_edition_current', {
                    initialValue: lotToEdit.artwork_edition_current ? lotToEdit.artwork_edition_current : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Edition Size">
                  {getFieldDecorator('artwork_edition_size', {
                    initialValue: lotToEdit.artwork_edition_size ? lotToEdit.artwork_edition_size : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Currency">
                  {getFieldDecorator('currency', {
                    initialValue: lotToEdit.currency ? lotToEdit.currency : null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {currencies.map(currency => (
                        <Select.Option key={currency} value={currency}>
                          {currency}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Estimate Min">
                  {getFieldDecorator('price_estimate_min', {
                    initialValue: lotToEdit.price_estimate_min ? lotToEdit.price_estimate_min : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Estimate Max">
                  {getFieldDecorator('price_estimate_max', {
                    initialValue: lotToEdit.price_estimate_max ? lotToEdit.price_estimate_max : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Sold">
                  {getFieldDecorator('price_sold', {
                    initialValue: lotToEdit.price_sold ? lotToEdit.price_sold : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Kind">
                  {getFieldDecorator('price_kind', {
                    initialValue: lotToEdit.price_kind ? lotToEdit.price_kind : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 2 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Guaranteed">
                  {getFieldDecorator('financial_guarantee', {
                    initialValue: lotToEdit.financial_guarantee || false,
                  })(
                    <Select>
                      <Select.Option key="gYes" value={true}>
                        Yes
                      </Select.Option>
                      <Select.Option key="gNo" value={false}>
                        No
                      </Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Unit">
                  {getFieldDecorator('artwork_measurements_unit', {
                    initialValue: lotToEdit.artwork_measurements_unit ? lotToEdit.artwork_measurements_unit : null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {measurements.map(measurement => (
                        <Select.Option key={measurement} value={measurement}>
                          {measurement}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Height">
                  {getFieldDecorator('artwork_measurements_height', {
                    initialValue: lotToEdit.artwork_measurements_height ? lotToEdit.artwork_measurements_height : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Width">
                  {getFieldDecorator('artwork_measurements_width', {
                    initialValue: lotToEdit.artwork_measurements_width ? lotToEdit.artwork_measurements_width : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Depth">
                  {getFieldDecorator('artwork_measurements_depth', {
                    initialValue: lotToEdit.artwork_measurements_depth ? lotToEdit.artwork_measurements_depth : null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Size Notes">
                  {getFieldDecorator('artwork_size_notes', {
                    initialValue: lotToEdit.artwork_size_notes ? lotToEdit.artwork_size_notes : null,
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Аrtwork Сondition In">
                  {getFieldDecorator('artwork_condition_in', {
                    initialValue: lotToEdit.artwork_condition_in ? lotToEdit.artwork_condition_in : null,
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Materials">
                  {getFieldDecorator('artwork_materials', {
                    initialValue: lotToEdit.artwork_materials ? lotToEdit.artwork_materials : null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label={mediumLabel}>
                  {getFieldDecorator('medium_final', {
                    initialValue: lotToEdit.medium_final ? lotToEdit.medium_final : null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {mediumFinals.map(([value, name]) => (
                        <Select.Option key={value} value={value}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Description">
                  {getFieldDecorator('artwork_description', {
                    initialValue: lotToEdit.artwork_description ? lotToEdit.artwork_description : null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Provenance">
                  {getFieldDecorator('artwork_provenance', {
                    initialValue: lotToEdit.artwork_provenance ? lotToEdit.artwork_provenance : null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Exhibited">
                  {getFieldDecorator('artwork_exhibited', {
                    initialValue: lotToEdit.artwork_exhibited ? lotToEdit.artwork_exhibited : null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Literature">
                  {getFieldDecorator('artwork_literature', {
                    initialValue: lotToEdit.artwork_literature ? lotToEdit.artwork_literature : null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

export { EditLotModal };
