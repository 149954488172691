import { createAction } from 'redux-actions';

import { signIn as signInCall } from 'auth/controllers';

import { saveUserInfo } from 'main/actions/user';
import User from 'main/records/User';

import SessionStorage from 'shared/tools/session-storage';
import { STORAGE_KEY } from 'shared/constants/app';

export const signInRequest = createAction('SIGN_IN_REQUEST');
export const signInReceive = createAction('SIGN_IN_RECEIVE');
export const signInFail = createAction('SIGN_IN_FAIL', errorMessage => ({ errorMessage }));

export const signIn = (email, password) => async dispatch => {
  dispatch(signInRequest());

  try {
    const { data } = await signInCall(email, password);

    const user = { ...data.user, api_token: data.token };

    SessionStorage.set(STORAGE_KEY, JSON.stringify(user));

    dispatch(signInReceive());

    dispatch(saveUserInfo(User.parse(user)));
  } catch (error) {
    dispatch(signInFail('Invalid email or password'));
  }
};
