import './index.scss';

import { BulkEditTypes, lotModel } from 'cleaning-lots/constants/lot-fields';
import { Button, Table, Typography } from 'antd';

import AddLotModal from 'cleaning-lots/containers/AddLotModal';
import AuctionsDropdown from 'cleaning-lots/containers/AuctionsDropdown';
import BulkEditLotsModal from 'cleaning-lots/containers/BulkEditLotsModal';
import EditColumnsModal from 'cleaning-lots/containers/EditColumnsModal';
import EditLotModal from 'cleaning-lots/containers/EditLotModal';
import { ITEMS_PER_PAGE } from 'shared/constants/items-per-page';
import Layout from 'main/containers/Layout';
import PropTypes from 'prop-types';
import React from 'react';
import Search from 'cleaning-lots/containers/CleaningSearch';
import { TABS } from 'cleaning-lots/constants/cleaning-lots';
import Tabs from 'shared/components/Tabs';

// import {addCustomLot} from "../../actions/admin-cleaning";

const orderLabels = {
  descend: 'desc',
  ascend: 'asc',
};

export default class CleaningUI extends React.PureComponent {
  state = {
    isEditLotModalOpened: false,
    isAddLotModalOpened: false,
    isEditColumnsModalOpened: false,
    isBulkEditModalOpened: false,
  };

  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isAllSelected: PropTypes.bool.isRequired,
    isBulkEditAllowed: PropTypes.bool.isRequired,

    getLots: PropTypes.func.isRequired,
    setSelectedLots: PropTypes.func.isRequired,
    setUnselectedLots: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setToInitialState: PropTypes.func.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    setSortBy: PropTypes.func.isRequired,
    setLotToEdit: PropTypes.func.isRequired,
    setBulkEditType: PropTypes.func.isRequired,
    setSelectAll: PropTypes.func.isRequired,
    publishLots: PropTypes.func.isRequired,
    unpublishLots: PropTypes.func.isRequired,
    publishExcludingIgnored: PropTypes.func.isRequired,

    queryParams: PropTypes.shape({ rawAuctionId: PropTypes.string }).isRequired,

    lotsRows: PropTypes.arrayOf(lotModel).isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.object.isRequired,
        key: PropTypes.string.isRequired,
        dataIndex: PropTypes.string.isRequired,
        fixed: PropTypes.string,
        render: PropTypes.func,
      }),
    ).isRequired,

    count: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    sortDir: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,

    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    unselectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,

    lotToEdit: lotModel,
    bulkEditType: PropTypes.string,

    tableWidth: PropTypes.number.isRequired,

    loadInitialData: PropTypes.func.isRequired,

    finishCleaning: PropTypes.func.isRequired,

    tableFilters: PropTypes.object.isRequired,
    setTableFilters: PropTypes.func.isRequired,
  };

  static defaultProps = {
    lotToEdit: null,
    bulkEditType: null,
  };

  componentDidMount() {
    this.props.loadInitialData();
  }

  componentWillUnmount() {
    this.props.setToInitialState();
  }

  onRowSelect = (selectedRowKeys) => {
    const { ids, unselectedIds, isAllSelected, setSelectedLots, setUnselectedLots } = this.props;

    if (isAllSelected) {
      const unselectedOnPageIds = ids.filter(
        id => !selectedRowKeys.includes(id) && !unselectedIds.includes(id),
      );

      const selectedOnPageIds = unselectedIds.filter(id => selectedRowKeys.includes(id));

      const allUnselectedIds = [...unselectedIds, ...unselectedOnPageIds].filter(
        id => !selectedOnPageIds.includes(id),
      );

      setUnselectedLots(allUnselectedIds);
    } else {
      setSelectedLots(selectedRowKeys);
    }
  };

  toggleSelectAll = () => {
    this.props.setSelectAll(!this.props.isAllSelected);
  };

  onEditLot = lot => {
    this.setState({
      isEditLotModalOpened: true,
    });
    this.props.setLotToEdit(lot);
  };

  onAddLot = lot => {
    this.setState({
      isAddLotModalOpened: true,
    });
    this.props.setLotToEdit(lot);
  };

  closeAddLotModal = () => {
    this.setState({
      isAddLotModalOpened: false,
    });
    this.props.setLotToEdit(null);
  };

  closeEditLotModal = () => {
    this.setState({
      isEditLotModalOpened: false,
    });
    this.props.setLotToEdit(null);
  };

  onBulkEditByField = type => {
    this.setState({
      isBulkEditModalOpened: true,
    });
    this.props.setBulkEditType(type);
  };

  closeBulkEditModal = () => {
    this.setState({
      isBulkEditModalOpened: false,
    });
    this.props.setBulkEditType(null);
  };

  openEditCurrencyModal = () => this.onBulkEditByField(BulkEditTypes.CURRENCY);

  openEditMeasurementsUnitModal = () => this.onBulkEditByField(BulkEditTypes.MEASUREMENTS_UNIT);

  openEditCleanerModal = () => this.onBulkEditByField(BulkEditTypes.CLEANER);

  openEditCleanerReviewStatusModal = () =>
    this.onBulkEditByField(BulkEditTypes.CLEANER_REVIEW_STATUS);

  openEditPriceKindModal = () => this.onBulkEditByField(BulkEditTypes.PRICE_KIND);

  openEditMediumFinalModal = () => this.onBulkEditByField(BulkEditTypes.MEDIUM_FINAL);

  finishCleaning = () => {
    console.log('[finishCleaning]', [this.props.activeAuctionId]);

    this.props.finishCleaning([this.props.activeAuctionId]);
  };

  toggleEditColumnsModal = () =>
    this.setState(prevState => ({
      isEditColumnsModalOpened: !prevState.isEditColumnsModalOpened,
    }));

  handleTableChange = (pagination, filters, sorter) => {
    const newSortDir = orderLabels[sorter.order];
    const newSortBy = sorter.field;
    const newPage = pagination.current;
    const newTableFilters = filters;
    const { sortDir, sortBy, currentPage, tableFilters } = this.props;

    const isSortDirChanged = newSortDir !== sortDir;
    const isSortByChanged = newSortBy !== sortBy;
    const isPageChanged = newPage !== currentPage;
    const isTableFiltersChanged = JSON.stringify(tableFilters) !== JSON.stringify(newTableFilters);

    if (isSortDirChanged) {
      this.props.setSortDirection(newSortDir);
    }

    if (isSortByChanged) {
      this.props.setSortBy(newSortBy);
    }

    if (isPageChanged) {
      this.props.setPage(newPage);
    }

    if (isTableFiltersChanged) {
      this.props.setTableFilters(newTableFilters);
    }

    if (isPageChanged || isSortDirChanged || isSortByChanged || isTableFiltersChanged) {
      this.props.getLots();
    }
  };

  render() {
    const {
      isEditLotModalOpened,
      isAddLotModalOpened,
      isEditColumnsModalOpened,
      isBulkEditModalOpened,
    } = this.state;

    const {
      isLoading,
      selectedIds,
      unselectedIds,
      lotsRows,
      count,
      currentPage,
      columns,
      isAdmin,
      lotToEdit,
      bulkEditType,
      isBulkEditAllowed,
      isAllSelected,
      publishLots,
      unpublishLots,
      publishExcludingIgnored,
      // addCustomLot,
      tableWidth,
      activeAuctionId,
    } = this.props;

    const rowSelection = {
      selectedRowKeys: selectedIds,
      onChange: this.onRowSelect,
    };

    return (
      <Layout className="cleaning-ui">
        <Tabs items={TABS} className="cleaning-ui__tabs" />
        <div className="cleaning-ui__buttons">
          <Button.Group>
            <Button type="primary" onClick={this.toggleEditColumnsModal}>
              Edit Columns
            </Button>
            {isAdmin && (
              <Button disabled={!isBulkEditAllowed} onClick={publishLots}>
                Publish Lots (only reviewed)
              </Button>
            )}
            {isAdmin && (
              <Button disabled={!isBulkEditAllowed} onClick={publishExcludingIgnored}>
                Publish Lots (all excluding Ignored)
              </Button>
            )}
            {isAdmin && (
              <Button disabled={!isBulkEditAllowed} onClick={unpublishLots}>
                Unpublish Lots
              </Button>
            )}
            <Button onClick={() => this.onAddLot(lotsRows[0])}>Add Lot</Button>
          </Button.Group>
        </div>
        <div className="cleaning-ui__buttons">
          <Button.Group>
            <Button disabled={!isBulkEditAllowed} onClick={this.openEditCurrencyModal}>
              Edit Currency
            </Button>
            <Button disabled={!isBulkEditAllowed} onClick={this.openEditMeasurementsUnitModal}>
              Edit Auction Measurements Unit
            </Button>
            {isAdmin && (
              <Button disabled={!isBulkEditAllowed} onClick={this.openEditCleanerModal}>
                Edit Cleaner
              </Button>
            )}
            <Button disabled={!isBulkEditAllowed} onClick={this.openEditCleanerReviewStatusModal}>
              Edit Cleaner Review Status
            </Button>
            <Button disabled={!isBulkEditAllowed} onClick={this.openEditPriceKindModal}>
              Edit Price Kind
            </Button>
            <Button disabled={!isBulkEditAllowed} onClick={this.openEditMediumFinalModal}>
              Edit Medium Final
            </Button>
          </Button.Group>
        </div>

        <div className="cleaning-ui__buttons">
          <Button.Group>
            <Button disabled={!!!activeAuctionId} onClick={this.finishCleaning}>
              Finish Cleaning
            </Button>
          </Button.Group>
        </div>

        <AuctionsDropdown
          selectedIds={selectedIds}
          unselectedIds={unselectedIds}
          isAllSelected={isAllSelected}
          count={count}
        />
        <Search />
        <Button type={!isAllSelected && 'primary'} onClick={this.toggleSelectAll}>
          {`${isAllSelected ? 'Deselect' : 'Select'} All Lots`}
        </Button>
        <Typography.Text
          style={{
            marginLeft: '20px',
          }}
        >
          Selected:
          {` ${isAllSelected ? count - unselectedIds.length : selectedIds.length}`}
        </Typography.Text>
        {lotToEdit && (
          <EditLotModal visible={isEditLotModalOpened} closeModal={this.closeEditLotModal} />
        )}
        {lotToEdit && (
          <AddLotModal visible={isAddLotModalOpened} closeModal={this.closeAddLotModal} />
        )}
        {bulkEditType && (
          <BulkEditLotsModal visible={isBulkEditModalOpened} closeModal={this.closeBulkEditModal} />
        )}
        <EditColumnsModal
          visible={isEditColumnsModalOpened}
          closeModal={this.toggleEditColumnsModal}
        />

        <Table
          rowKey="raw_lot_id"
          scroll={{ x: tableWidth, y: 'calc(100vh - 535px)' }}
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={lotsRows}
          columns={[
            ...columns,
            {
              title: 'Edit',
              key: 'edit',
              dataIndex: 'edit',
              render: (value, record) => (
                <Button
                  type="primary"
                  shape="circle"
                  icon="edit"
                  onClick={() => this.onEditLot(record)}
                />
              ),
              fixed: 'right',
              width: 73,
            },
          ]}
          size="small"
          pagination={{
            pageSize: ITEMS_PER_PAGE,
            total: count,
            position: 'top',
            current: currentPage,
            size: 'small',
          }}
          onChange={this.handleTableChange}
        />
      </Layout>
    );
  }
}
