import { handleActions } from 'redux-actions';

import { saveUserInfo, clearUserAccountData } from 'main/actions/user';

import UserReducer from 'main/records/UserReducer';
import User from 'main/records/User';

import SessionStorage from 'shared/tools/session-storage';
import { STORAGE_KEY } from 'shared/constants/app';

const storedUser = JSON.parse(SessionStorage.get(STORAGE_KEY));

const initState = storedUser
  ? new UserReducer({
      userInfo: User.parse(storedUser),
    })
  : new UserReducer({});

export default handleActions(
  {
    [saveUserInfo]: (state, action) => state.set('userInfo', action.payload.userInfo),
    [clearUserAccountData]: state => state.set('userInfo', null),
  },
  initState,
);
