import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => !!state.user.get('userInfo'),
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !state.user.get('userInfo'),
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const userIsAdmin = connectedRouterRedirect({
  redirectPath: '/cleaning-lots',
  authenticatedSelector: state => state.user.getIn(['userInfo', 'role', 'name']) === 'admin',
  wrapperDisplayName: 'UserIsAdmin',
});
