import { createAction } from 'redux-actions';
import { Request } from 'shared/tools/request';
import { notification } from 'antd';

import { PUBLISH_TYPE } from 'cleaning-lots/constants/publish';

import { getAuction } from './admin-cleaning';

const openNotesModal = createAction('OPEN_NOTES_MODAL');
const closeNotesModal = createAction('CLOSE_NOTES_MODAL');

const openNotification = (type, message, description) => {
  notification[type]({ message, description });
};

const setNotes = (selectedIds, { clean_first, use_apr, custom_note }) => async dispatch => {
  try {
    await Request.post('/api/raw-auction/notes', {
      data: {
        ids: selectedIds,
        clean_first,
        use_apr,
        custom_note,
      },
    });

    dispatch(getAuction());
  } catch (err) {
    openNotification('error', 'Error has been occurred while publishing lots');
  }
};

export { openNotesModal, closeNotesModal, setNotes };
