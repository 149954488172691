import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { setSelectedAuctions, assignCleaner } from 'cleaning-lots/actions/admin-cleaning';
import { getCleaners } from 'cleaning-lots/actions/cleaners';
import { AssignCleanerModal } from 'cleaning-lots/components/AdminCleaningUI/AssignCleanerModal';
import { denormalizeRows } from '../normalization/denormalize';

const stateToProps = state => ({
  cleaners: denormalizeRows(state.cleaners),
});

const dispatchToProps = {
  getCleaners,
  assignCleaner,
  setSelectedAuctions,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(AssignCleanerModal);
