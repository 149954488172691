import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { routerMiddleware } from 'connected-react-router';

import createRootReducer from 'main/reducers';

import history from 'shared/tools/history';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = (initialState = {}) =>
  createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)),
  );

export default store();
