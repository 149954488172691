import { createAction } from 'redux-actions';
import { notification } from 'antd';

import { Request } from 'shared/tools/request';

export const loadArtistQualifiersRequest = createAction('LOAD_ARTIST_QUALIFIERS_REQUEST');
export const loadArtistQualifiersSuccess = createAction('LOAD_ARTIST_QUALIFIERS_SUCCESS');
export const loadArtistQualifiersError = createAction('LOAD_ARTIST_QUALIFIERS_ERROR');

export const loadArtistQualifiers = () => async dispatch => {
  dispatch(loadArtistQualifiersRequest());

  try {
    const artistQualifiers = await Request.get('/api/artist/qualifiers');

    dispatch(loadArtistQualifiersSuccess(artistQualifiers.data));
  } catch (err) {
    dispatch(loadArtistQualifiersError());

    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });
  }
};
