import { handleActions } from 'redux-actions';
import produce from 'immer';

import { openNotesModal, closeNotesModal } from 'cleaning-lots/actions/notes';

const initState = {
  isNotesModalOpened: false,
};

const notesReducer = handleActions(
  {
    [openNotesModal]: produce(draft => {
      draft.isNotesModalOpened = true;
    }),
    [closeNotesModal]: produce(draft => {
      draft.isNotesModalOpened = false;
    }),
  },
  initState,
);

export { notesReducer };
