import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { setFilters, setPage, getArtists } from 'artist/actions/artist-page';

import { Search } from 'artist/components/search/search';
import { getNotNullFields } from 'shared/tools/form';

import { ARTIST_STATUS } from 'shared/constants/app';

const FIELDS = [
  {
    placeholder: 'Id',
    name: 'id',
    type: 'number',
    isCleanerAvailable: true,
  },
  {
    placeholder: 'Name',
    name: 'name',
    isCleanerAvailable: true,
  },
  {
    placeholder: 'Birth',
    name: 'birth',
    type: 'number',
    isCleanerAvailable: true,
  },
  {
    placeholder: 'Death',
    name: 'death',
    type: 'number',
    isCleanerAvailable: true,
  },
  {
    placeholder: 'Qualifier',
    name: 'qualifier',
    isCleanerAvailable: false,
  },
  {
    placeholder: 'Qualified Artist Id',
    name: 'qualified_artist_id',
    type: 'number',
    isCleanerAvailable: false,
  },
];

const ARTIST_STATUSES = Object.values(ARTIST_STATUS);

const dispatchToProps = dispatch => ({
  searchArtists: fields => {
    const filledFields = getNotNullFields(fields);

    dispatch(setPage(1));
    dispatch(setFilters(filledFields));
    dispatch(getArtists());
  },
});

const checkIfCleaner = state => state.user.userInfo.role.name === 'cleaner';

const checkIfVisibleForCleaner = field => field.isCleanerAvailable;

const getFields = state =>
  checkIfCleaner(state) ? FIELDS.filter(checkIfVisibleForCleaner) : FIELDS;

export default compose(
  connect(
    state => ({
      fields: getFields(state),
      statuses: ARTIST_STATUSES,
    }),
    dispatchToProps,
  ),
  Form.create(),
)(Search);
