import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { setSelectedAuctions, deleteAuctions } from 'cleaning-lots/actions/admin-cleaning';
import { DeleteModal } from 'cleaning-lots/components/AdminCleaningUI/DeleteModal';

const stateToProps = state => ({});

const dispatchToProps = {
  deleteAuctions,
  setSelectedAuctions,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(DeleteModal);
