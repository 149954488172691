import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Form } from 'antd';

import { EditColumnsModal } from 'cleaning-lots/components/CleaningUI/EditColumnsModal';
import { setColumns } from 'cleaning-lots/actions/cleaning';
import { getCleaningUiColumns } from 'cleaning-lots/constants/table-columns';

const getColumns = state => {
  const isAdmin = state.user.userInfo.role.name === 'admin';
  const cleaningUiColumns = getCleaningUiColumns(state.lots.reviewRequiredReasons);

  return isAdmin
    ? cleaningUiColumns.filter(column => !column.required)
    : cleaningUiColumns.filter(column => column.key !== 'cleaner' && !column.required);
};

const stateToProps = state => ({
  columns: getColumns(state),
});

const dispatchToProps = {
  setColumns,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(EditColumnsModal);
