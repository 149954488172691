import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select, Button } from 'antd';
import { AuctionPriorityLabels } from 'cleaning-lots/constants/raw-auction';

import './index.scss';

class AuctionsDropdown extends PureComponent {
  static propTypes = {
    auctions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        auction_house_name: PropTypes.string,
        lot_count: PropTypes.number.isRequired,
      }),
    ).isRequired,
    activeAuctionId: PropTypes.number,
    pendingJobsCount: PropTypes.number,
    isAdmin: PropTypes.bool.isRequired,

    count: PropTypes.number.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    unselectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    isAllSelected: PropTypes.bool.isRequired,

    selectAuction: PropTypes.func.isRequired,
    crawlAuctionPrices: PropTypes.func.isRequired,
    recrawlAuction: PropTypes.func.isRequired,
    getPendingJobsCount: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeAuctionId: null,
    pendingJobsCount: 0,
  };

  onChange = auction => {
    this.props.selectAuction(auction.key);
  };

  getNumLotsCount() {
    const { selectedIds, unselectedIds, isAllSelected, count } = this.props;
    if (isAllSelected) {
      return count - unselectedIds.length;
    }

    if (selectedIds.length) {
      return selectedIds.length;
    }

    return count;
  }

  render() {
    const {
      auctions,
      activeAuctionId,
      pendingJobsCount,
      isAdmin,
      selectedIds,
      unselectedIds,
      isAllSelected,
    } = this.props;

    return (
      <div className="auctions-dropdown">
        <div className="auctions-dropdown__labels">
          id / auction name / auction house name / lot count / status / priority / endnotes
        </div>
        <Select
          labelInValue
          value={{ key: activeAuctionId }}
          style={{ width: 700 }}
          onChange={this.onChange}
        >
          <Select.Option key={-1} value={null}>
            None
          </Select.Option>
          {auctions.map(auction => (
            <Select.Option key={auction.id} value={auction.id}>
              <div className="auctions-dropdown__fields">
                <span className="auctions-dropdown__field">{auction.id}</span>
                <span className="auctions-dropdown__field auctions-dropdown__field_name">
                  {`/ ${auction.name}`}
                </span>
                <span className="auctions-dropdown__field">
                  {`/ ${auction.auction_house_name || 'N/A'}`}
                </span>
                <span className="auctions-dropdown__field">{`/ ${auction.lot_count}`}</span>
                <span className="auctions-dropdown__field">{`/ ${auction.auction_status}`}</span>
                {auction.priority && (
                  <span className="auctions-dropdown__field">
                    {`/ ${AuctionPriorityLabels[auction.priority]} Priority`}
                  </span>
                )}
                {auction.use_apr != null && (
                  <span className="auctions-dropdown__field">
                    {`/ ${auction.use_apr ? '' : 'Do Not '} Use APR`}
                  </span>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
        {activeAuctionId && isAdmin && (
          <span>
            <Button
              size="default"
              type="primary"
              style={{ marginLeft: 20 }}
              disabled={pendingJobsCount}
              onClick={() => this.props.crawlAuctionPrices(activeAuctionId, selectedIds, unselectedIds, isAllSelected)}
            >
              Crawl Prices ({this.getNumLotsCount()} lots)
            </Button>

            <Button
              size="default"
              type="primary"
              disabled={pendingJobsCount}
              style={{ marginLeft: 20 }}
              onClick={() => this.props.recrawlAuction(activeAuctionId, selectedIds, unselectedIds, isAllSelected)}
            >
              Recrawl Lots ({this.getNumLotsCount()} lots)
            </Button>

            <Button
              size="default"
              type="secondary"
              style={{ marginLeft: 20 }}
              onClick={() => this.props.getPendingJobsCount()}
            >
              {pendingJobsCount}
              &nbsp;Pending Jobs (Refresh)
            </Button>
          </span>
        )}
      </div>
    );
  }
}

export { AuctionsDropdown };
