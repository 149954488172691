import { handleActions } from 'redux-actions';

import { signInRequest, signInReceive, signInFail } from 'auth/actions/login';

import LoginReducer from 'auth/records/LoginReducer';

export default handleActions(
  {
    [signInRequest]: state => state.set('isLoggingIn', true).set('errorMessage', ''),
    [signInReceive]: state => state.set('isLoggingIn', false).set('errorMessage', ''),
    [signInFail]: (state, action) => state.set('isLoggingIn', false).set('errorMessage', action.payload.errorMessage || 'Error'),
  },
  new LoginReducer(),
);
