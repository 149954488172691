import { Table, Typography } from 'antd';

import Layout from 'main/containers/Layout';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import './index.scss';

const columns = [
  {
    title: 'Auction House Name',
    key: 'auctionHouseName',
    dataIndex: 'auctionHouseName',
    sorter: false,
    render: value => value || '',
  },
  {
    title: 'Pending Jobs',
    key: 'PENDING',
    dataIndex: 'PENDING',
    sorter: false,
    render: value => value,
  },
  {
    title: 'In Progress Jobs',
    key: 'IN_PROGRESS',
    dataIndex: 'IN_PROGRESS',
    sorter: false,
    render: value => value,
  },
  {
    title: 'Failed Jobs',
    key: 'FAILED',
    dataIndex: 'FAILED',
    sorter: false,
    render: value => value,
  },
];

const { Title } = Typography;
export default class SystemStatus extends React.PureComponent {
  static propTypes = {
    loadInitialData: PropTypes.func.isRequired,
    setToInitialState: PropTypes.func.isRequired,
    systemStatusData: PropTypes.arrayOf(
      PropTypes.shape({
        auctionHouseName: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  componentDidMount() {
    this.props.loadInitialData();
  }

  componentWillUnmount() {
    this.props.setToInitialState();
  }

  render() {
    const { systemStatusData } = this.props;
    return (
      <Layout>
        <Title level={4}>Crawl Upcoming Auctions Job</Title>
        <Table
          rowKey="auctionHouseId"
          // loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={systemStatusData.crawlUpcomingAuctionsJobs}
          columns={columns}
          size="small"
          pagination={false}
        />

        <Title level={4}>Crawl Past Auctions Job</Title>
        <Table
          rowKey="auctionHouseId"
          // loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={systemStatusData.crawlPastAuctionsJobs}
          columns={columns}
          size="small"
          pagination={false}
        />

        <Title level={4}>Auction Crawl Jobs</Title>
        <Table
          rowKey="auctionHouseId"
          // loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={systemStatusData.crawlAuctionJobs}
          columns={columns}
          size="small"
          pagination={false}
          expandedRowRender={record => (
            <div>
              <div>Failed Url's</div>
              {record.FAILED_URLS.map(url => (
                <div>
                  <a href={url} target="_blank">
                    {url}
                  </a>
                </div>
              ))}
            </div>
          )}
        />

        <Title level={4}>Lot Crawl Jobs</Title>
        <Table
          rowKey="auctionHouseId"
          // loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={systemStatusData.crawlLotJobs}
          columns={columns}
          size="small"
          pagination={false}
        />

        <Title level={4}>Lot Parse Jobs</Title>
        <Table
          rowKey="auctionHouseId"
          // loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={systemStatusData.parseLotJobs}
          columns={columns}
          size="small"
          pagination={false}
        />
      </Layout>
    );
  }
}
