import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Form } from 'antd';

import Login from 'auth/components/Login';

import { signIn } from 'auth/actions/login';

const mapStateToProps = state => ({
  errorMessage: state.auth.login.get('errorMessage'),
  isLoggingIn: state.auth.login.get('isLoggingIn'),
});

const mapDispatchToProps = dispatch => ({
  signIn: (email, password) => dispatch(signIn(email, password)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  Form.create(),
)(Login);
