import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  setSelectedAuctions,
  editPriority,
  closeEditModal as closeModal,
} from 'cleaning-lots/actions/admin-cleaning';
import EditPriorityModal from 'cleaning-lots/components/AdminCleaningUI/EditPriorityModal';
import { AuctionPriority, AuctionPriorityLabels } from '../constants/raw-auction';

const priorities = Object.values(AuctionPriority).map(item => ({
  label: AuctionPriorityLabels[item],
  value: item,
  key: item,
}));

const stateToProps = state => ({
  priorities,
  isVisible: state.auctions.isEditModalVisible,
});

const dispatchToProps = {
  editPriority,
  closeModal,
  setSelectedAuctions,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(EditPriorityModal);
