import { isNumber } from 'lodash';

export const getMediumColorByParserConfidence = confidence => {
  if (!isNumber(confidence)) {
    return null;
  }

  let color = '#ff0000';

  if (confidence >= 90) {
    color = '#008000';
  }

  if (confidence >= 70 && confidence < 90) {
    color = '#FF9800';
  }

  return color;
};
