import artistQualifierReducer from './artist-qualifier';
import { artistReducer } from 'artist/reducers/artist';
import { auctionsReducer } from 'cleaning-lots/reducers/admin-cleaning';
import authReducer from 'auth/reducers';
import { cleanersReducer } from 'cleaning-lots/reducers/cleaners';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { dashboardReducer } from 'dashboard/reducers/dashboard';
import { systemStatusReducer } from 'system-status/reducers/system-status';
import { lotsReducer } from 'cleaning-lots/reducers/cleaning';
import { notesReducer } from 'cleaning-lots/reducers/notes';
import { publishReducer } from 'cleaning-lots/reducers/publish';
import { unpublishReducer } from 'cleaning-lots/reducers/unpublish';
import userReducer from './user';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    auth: authReducer,
    auctions: auctionsReducer,
    lots: lotsReducer,
    artists: artistReducer,
    cleaners: cleanersReducer,
    publish: publishReducer,
    unpublish: unpublishReducer,
    notes: notesReducer,
    dashboard: dashboardReducer,
    artistQualifier: artistQualifierReducer,
    systemStatus: systemStatusReducer,
  });

export default createRootReducer;
