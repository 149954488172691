import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';

import Tabs from 'shared/components/Tabs';

import './index.scss';

const TABS = [
  {
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    name: 'Cleaning Lots',
    path: '/cleaning-lots',
  },
  {
    name: 'Artist',
    path: '/artist',
  },
  {
    name: 'System Status',
    path: '/system-status',
  },
];

const Layout = ({ children, onLogOut, className }) => {
  return (
    <div className={classNames('layout', className)}>
      <div className="layout__header">
        <div className="layout__tabs">
          <Tabs items={TABS} />
        </div>
        <Button className="" onClick={onLogOut}>
          Logout
        </Button>
      </div>

      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),

  onLogOut: PropTypes.func.isRequired, // from container

  className: PropTypes.string,
};

Layout.defaultProps = {
  children: <div />,

  className: '',
};

export default Layout;
