module.exports = {
  empty_aw_name: 'Empty artwork name',
  empty_lot_img: 'Empty lot image',
  empty_aid: 'Empty artist ID',
  single_an: 'Single artist name',
  empty_medium: 'Medium Final is undetermined',
  empty_awm: 'Artwork Material is empty',
  empty_awd: 'Empty artwork dimensions',
  empty_au_name: 'Empty auction name',
  empty_au_num: 'Empty auction number',
  empty_aw_num: 'Empty lot number',
  wrong_date: 'Incorrect date',
  empty_estimate: 'Empty estimate price',
  empty_price_sold: 'Empty price sold',
  empty_curr: 'No currency',
  aw_name_eq_an: 'Artwork Name is similar to Artist Name',
  aw_name_qualifier: 'Artwork Name contains artist qualifier',
};
