import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, Form, InputNumber, Select } from 'antd';

import { PUBLISH_TYPE } from 'cleaning-lots/constants/publish';

const PUBLISH_TYPE_LABELS = {
  [PUBLISH_TYPE.ALL_REVIEWED_LOTS]: 'All reviewed lots',
  [PUBLISH_TYPE.ALL_LOTS]: 'All lots',
  [PUBLISH_TYPE.ALL_LOTS_WITH_MEDIUM_PARSER_CONFIDENCE]:
    'All lots with medium_parser_confidence >= XX%',
};

const PublishModal = ({ selectedIds, publish, isVisible, closeModal, form }) => {
  const { getFieldDecorator, getFieldValue, getFieldError } = form;

  const onOk = () => {
    if (!getFieldError('mediumParserConfidence')) {
      const publishType = getFieldValue('publishType') || null;
      const mediumParserConfidence = getFieldValue('mediumParserConfidence');

      publish(selectedIds, publishType, mediumParserConfidence);
    }
  };

  return (
    <Modal title="Publish" visible={isVisible} onOk={onOk} onCancel={closeModal}>
      <Form>
        <Form.Item label="Publish type">
          {getFieldDecorator('publishType', {
            initialValue: PUBLISH_TYPE.ALL_REVIEWED_LOTS,
          })(
            <Select>
              {_.map(PUBLISH_TYPE_LABELS, (value, key) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        {getFieldValue('publishType') === PUBLISH_TYPE.ALL_LOTS_WITH_MEDIUM_PARSER_CONFIDENCE && (
          <Form.Item label="medium_parser_confidence >=">
            {getFieldDecorator('mediumParserConfidence', {
              initialValue: 80,
              rules: [
                {
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                },
              ],
            })(<InputNumber />)}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

PublishModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
  }).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,

  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,

  publish: PropTypes.func.isRequired,
};

export default PublishModal;
