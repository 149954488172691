import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';

import SessionStorage from 'shared/tools/session-storage';

import { STORAGE_KEY } from 'shared/constants/app';

export const saveUserInfo = createAction('SAVE_USER_INFO', userInfo => ({ userInfo }));

export const clearUserAccountData = createAction('LOG_OUT');

export const logOut = () => dispatch => {
  dispatch(clearUserAccountData());

  SessionStorage.remove(STORAGE_KEY);

  dispatch(push('/login'));
};
