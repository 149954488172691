import {
  getDashboardData,
  getDashboardDataError,
  getDashboardDataSuccess,
  setToInitialState,
} from 'dashboard/actions/dashboard';

import { errorProducer } from 'shared/helpers/producers';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const initState = {
  dashboardData: [],
};

const setDashboardDataProducer = (draft, action) => {
  draft.dashboardData = action.payload.map(item => ({ ...item, key: item.auctionHouseId }));
  draft.isLoading = false;
};

export const dashboardReducer = handleActions(
  {
    [setToInitialState]: produce(draft => {
      draft.dashboardData = [];
    }),
    [getDashboardData]: produce(draft => {
      draft.isLoading = true;
    }),
    [getDashboardDataSuccess]: produce(setDashboardDataProducer),
    [getDashboardDataError]: produce(errorProducer),
  },
  initState,
);
