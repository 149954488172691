import { createAction } from 'redux-actions';
import { ITEMS_PER_PAGE } from 'shared/constants/items-per-page';

export const createSetFiltersAction = type => createAction(type, (filters = {}) => ({ filters }));

export const createSetPageAction = type =>
  createAction(type, (currentPage = 1) => ({
    offset: ITEMS_PER_PAGE * (currentPage - 1),
    currentPage,
  }));
