import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { bulkUpdateLots } from 'cleaning-lots/actions/cleaning';

import { BulkEditLotsModal } from 'cleaning-lots/components/CleaningUI/BulkEditLotsModal';

import { removeEmptyFields } from 'shared/tools/form';
import { denormalizeRows } from '../normalization/denormalize';

const stateToProps = state => ({
  auctions: state.auctions.fullList,
  cleaners: denormalizeRows(state.cleaners),
  type: state.lots.bulkEditType,
});

const dispatchToProps = dispatch => ({
  bulkUpdateLots: fields => dispatch(bulkUpdateLots(removeEmptyFields(fields))),
});

export default compose(
  connect(
    stateToProps,
    dispatchToProps,
  ),
  Form.create(),
)(BulkEditLotsModal);
