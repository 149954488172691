import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Col, Row } from 'antd';

import { hasErrors } from 'shared/tools/form';
import img from 'assets/art_metadata_logo_small.png';

import './index.scss';

const Login = ({
  form: { getFieldDecorator, getFieldsError, validateFields },
  errorMessage,
  isLoggingIn,
  signIn,
}) => {
  const onSignInClick = e => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        signIn(values.email, values.password);
      }
    });
  };

  return (
    <Row type="flex" justify="center" className="login">
      <Col xs={24} sm={10} md={8} xl={6} xxl={4}>
        <div className="login__img-container">
          <img className="login__img" src={img} alt="Logo" />
        </div>
        <Form onSubmit={onSignInClick}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                { required: true, message: 'Please input your username!' },
              ],
            })(<Input autoFocus type="email" size="large" placeholder="Email" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your password!' }],
            })(<Input type="password" size="large" placeholder="Password" />)}
          </Form.Item>

          {errorMessage && <div className="login__error-message">{errorMessage}</div>}

          <Form.Item>
            <Button
              type="primary"
              disabled={hasErrors(getFieldsError())}
              htmlType="submit"
              className="login-form-button"
              size="large"
              block
              loading={isLoggingIn}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldsError: PropTypes.func.isRequired,
  }).isRequired,
  errorMessage: PropTypes.string,
  isLoggingIn: PropTypes.bool.isRequired,
};

Login.defaultProps = {
  errorMessage: '',
};

export default React.memo(Login);
