
import React from 'react';
import { Upload, Icon, Button } from 'antd';

import './index.scss';

const getObjectUrl = img => URL.createObjectURL(img);

const mapFile = file => file.response 
  ? ({ ...file, url: getObjectUrl(file.originFileObj) }) 
  : file;

class ImageLoader extends React.PureComponent {
  state = {
    loading: false,
    fileList: this.props.fileList,
  };

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }
    return null;
  }

  handleChange = (info) => {
    const fileList = info.fileList.map(mapFile);

    this.setState({ fileList });
    this.triggerChange({ fileList });
  };

  triggerChange = (changedValue) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({
        ...changedValue,
      });
    }
  };

  render() {
    const { accept } = this.props;
    const { fileList } = this.state;

    return (
      <Upload
        name="image"
        listType="picture-card"
        className="image-uploader"
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        onChange={this.handleChange}
        accept={accept}
        fileList={fileList}
      >
        {fileList.length >= 1 ? null : (
          <Button>
            <Icon type="upload" /> Upload
          </Button>
        )}
      </Upload>
    );
  }
}
export { ImageLoader };