import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import store from 'main/store';

import history from 'shared/tools/history';

import authRoutes from 'auth/routes';
import dashboardRoutes from 'dashboard/routes';
import cleaningLotsRoutes from 'cleaning-lots/routes';
import artistRoutes from 'artist/routes';
import systemStatusRoutes from 'system-status/routes';

const Main = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        {authRoutes()}
        {cleaningLotsRoutes()}
        {dashboardRoutes()}
        {artistRoutes()}
        {systemStatusRoutes()}
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default Main;
