import { handleActions } from 'redux-actions';
import produce from 'immer';

import {
  loadArtistQualifiersRequest,
  loadArtistQualifiersSuccess,
  loadArtistQualifiersError,
} from 'main/actions/artist-qualifier';

const initState = {
  isLoading: false,
  items: [],
};

export default handleActions(
  {
    [loadArtistQualifiersRequest]: produce(draft => {
      draft.isLoading = true;
    }),
    [loadArtistQualifiersSuccess]: produce((draft, action) => {
      draft.isLoading = false;
      draft.items = action.payload;
    }),
    [loadArtistQualifiersError]: produce(draft => {
      draft.isLoading = false;
    }),
  },
  initState,
);
