import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { updateLot, navigateToPrevLot, navigateToNextLot } from 'cleaning-lots/actions/cleaning';

import { EditLotModal } from 'cleaning-lots/components/CleaningUI/EditLotModal';
import { removeEmptyFields } from 'shared/tools/form';
import { formatDate } from 'shared/helpers/format';
import { getCurrentLotIndex } from 'cleaning-lots/helpers/get-current-lot-index';
import { denormalizeRows } from '../normalization/denormalize';

const stateToProps = state => {
  const {
    lots: { isLoading, lotToEdit, ids, currentPage, pagesCount },
  } = state;

  const currentLotIndex = getCurrentLotIndex(lotToEdit, ids);

  return {
    cleaners: denormalizeRows(state.cleaners),
    isAdmin: state.user.userInfo.role.name === 'admin',
    isLoading,
    lotToEdit,
    isPrevButtonDisabled: currentLotIndex === 0 && currentPage === 1,
    isNextButtonDisabled: currentLotIndex === ids.length - 1 && currentPage === pagesCount,
    artistQualifiers: state.artistQualifier.items.map(x => x.name),
  };
};

const dispatchToProps = dispatch => ({
  updateLot: (id, fields) => {
    const data = fields.auction_start_date
      ? {
          ...fields,
          auction_start_date: formatDate(fields.auction_start_date),
          auction_end_date: formatDate(fields.auction_end_date || fields.auction_start_date),
        }
      : fields;

    const cleanedData = removeEmptyFields(data);
    if (cleanedData.financial_guarantee === null) cleanedData.financial_guarantee = false;
    dispatch(updateLot(id, cleanedData));
  },
  navigateToPrevLot: () => dispatch(navigateToPrevLot()),
  navigateToNextLot: () => dispatch(navigateToNextLot()),
});

export default compose(
  connect(
    stateToProps,
    dispatchToProps,
  ),
  Form.create(),
)(EditLotModal);
