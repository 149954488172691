import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Switch } from 'antd';

import './index.scss';

class EditColumnsModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,

    closeModal: PropTypes.func.isRequired,
    setColumns: PropTypes.func.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.object.isRequired,
        key: PropTypes.string.isRequired,
        dataIndex: PropTypes.string.isRequired,
        fixed: PropTypes.string,
        render: PropTypes.func,
      }),
    ).isRequired,
  };

  onOk = () => {
    const {
      form: { getFieldsValue },
      setColumns,
      closeModal,
    } = this.props;

    setColumns(getFieldsValue());
    closeModal();
  };

  render() {
    const {
      form: { getFieldDecorator },
      columns,
    } = this.props;

    return (
      <Modal
        title="Choose displayed columns"
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        onOk={this.onOk}
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
      >
        <Form>
          {columns.map(column => (
            <Form.Item
              label={column.key}
              labelCol={{ span: 11 }}
              className="edit-column-field"
              style={{ marginBottom: 8 }}
              key={column.key}
            >
              {getFieldDecorator(column.key, { valuePropName: 'checked', initialValue: true })(
                <Switch size="small" />,
              )}
            </Form.Item>
          ))}
        </Form>
      </Modal>
    );
  }
}

export { EditColumnsModal };
