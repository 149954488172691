import { closeUnpublishModal, unpublish } from 'cleaning-lots/actions/unpublish';

import { Form } from 'antd';
import UnpublishModal from 'cleaning-lots/components/AdminCleaningUI/UnpublishModal';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const stateToProps = state => ({
  isVisible: state.unpublish.isUnpublishModalOpened,
});

const dispatchToProps = {
  unpublish,
  closeModal: closeUnpublishModal,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(UnpublishModal);
