import { connect } from 'react-redux';
import Artist from 'artist/components';

import {
  getArtists,
  setPage,
  setSortDirection,
  setSortBy,
  setArtistToEdit,
  openCreateArtistModal,
  closeCreateArtistModal,
  openMergeArtistModal,
  closeMergeArtistModal,
  setToInitialState,
} from 'artist/actions/artist-page';

import { ARTIST_TABLE_COLUMNS } from 'artist/constants/table-columns';
import { loadArtistQualifiers } from 'main/actions/artist-qualifier';

const denormalizeRows = ({ ids, items }) => ids.map(id => items[id]);

const stateToProps = state => ({
  artistRows: denormalizeRows(state.artists),
  count: state.artists.count,
  isLoading: state.artists.isLoading,

  currentPage: state.artists.currentPage,
  columns: ARTIST_TABLE_COLUMNS,
  sortDir: state.artists.sortDir,
  sortBy: state.artists.sortBy,
  artistToEdit: state.artists.artistToEdit,
  isAdmin: state.user.userInfo.role.name === 'admin',
  isCreateArtistModalOpen: state.artists.isCreateArtistModalOpen,
  isMergeArtistModalOpen: state.artists.isMergeArtistModalOpen,
});

const dispatchToProps = {
  getArtists,
  loadQualifiers: loadArtistQualifiers,
  setPage,
  setSortDirection,
  setSortBy,
  setArtistToEdit,
  openCreateArtistModal,
  closeCreateArtistModal,
  openMergeArtistModal,
  closeMergeArtistModal,
  setToInitialState,
};

export default connect(stateToProps, dispatchToProps)(Artist);
