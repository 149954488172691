export const denormalizeAndMapItems = ({ ids, items }, { items: cleaners }) =>
  ids.map(id => {
    const item = items[id];
    const cleaner = cleaners[item.cleaner_id];
    return {
      ...item,
      cleaner,
    };
  });

export const denormalizeRows = ({ ids, items }) => ids.map(id => items[id]);
