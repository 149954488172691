import { ITEMS_PER_PAGE } from 'shared/constants/items-per-page';

export const loadingProducer = draft => {
  draft.isLoading = true;
};

export const successProducer = draft => {
  draft.success = true;
  draft.isLoading = false;
};

export const errorProducer = draft => {
  draft.success = false;
  draft.isLoading = false;
};

export const receiveDataProducer = (draft, action) => {
  if (action.payload.itemToEditId) {
    draft.lotToEdit = action.payload.items[action.payload.itemToEditId];
  }
  draft.ids = action.payload.ids;
  draft.items = action.payload.items;
  draft.count = action.payload.count;
  draft.pagesCount = Math.ceil(action.payload.count / ITEMS_PER_PAGE);
  draft.success = true;
  draft.isLoading = false;
};

export const setSelectedIds = (draft, action) => {
  draft.selectedIds = action.payload.selectedIds;
};

export const setUnselectedIds = (draft, action) => {
  draft.unselectedIds = action.payload.unselectedIds;
};

export const setEmptySelectedIds = draft => {
  draft.selectedIds = [];
};

export const setFiltersProducer = (draft, action) => {
  draft.filters = action.payload.filters;
};

export const setPageProducer = (draft, action) => {
  draft.currentPage = action.payload.currentPage;
  draft.offset = action.payload.offset;
};
